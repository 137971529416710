import { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes, { bool, number, func, string } from 'prop-types';
import { logActivity } from 'services/activity';
import {
   getBusinessDetails,
   getBusinessPcr,
   getBizMonthlyInflow,
   getBizWeeklyInflow,
} from 'services/businessDetails';
import { Spinner } from 'components/spinner';
import toast from 'react-hot-toast';
import { AlertMessage } from 'components/message';
import ProgressReport from 'components/progress-report';
import Attention from 'components/documents-attention';
import DirectorsInfo from 'containers/business-details/utils/directors-info';
import UserProfileComponent from 'containers/business-details/utils/user-profile';
import Summary from 'components/summary';
import { BarChart } from 'components/chart';
import { OverviewWrapper } from '../utils/overview.style';
import BusinessInfo from './business-info';
import OwnerInfo from './owner-info';

const Overview = ({ businessAccountId, businessDetailId, noCloseIcon, changePlanFocus, plans }) => {
   const [bizDetails, setBizDetails] = useState({});
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState('');
   const [bizPcr, setBizPcr] = useState('');
   const [weeklyData, setWeeklyData] = useState([]);
   const [monthlyData, setMonthlyData] = useState([]);
   const [currentFilter, setCurrentFilter] = useState('weekly');
   const [selectedYear, setSelectedYear] = useState(`${moment().format('YYYY')}`);
   const [years, setYears] = useState([]);

   const fetchBizMonthlyInflow = async () => {
      try {
         const res = await getBizMonthlyInflow({
            biz_account_id: businessAccountId,
            ...(Object.keys(selectedYear)?.length > 1 && {
               start_date: `${selectedYear}-01-01`,
               end_date: `${selectedYear}-12-31`,
            }),
         });
         setMonthlyData(res.data);
      } catch (err) {
         toast.error(err?.message);
      }
   };

   const fetchBizWeeklyInflow = async () => {
      try {
         const res = await getBizWeeklyInflow({
            biz_account_id: businessAccountId,
         });
         setWeeklyData(res.data);
      } catch (err) {
         toast.error(err?.message);
      }
   };

   useEffect(() => {
      fetchBizMonthlyInflow();
   }, [selectedYear]);

   useEffect(() => {
      (async function MontlyInflowStats() {
         const currentYear = new Date().getFullYear();
         const range = (start, stop, step) =>
            Array.from({ length: (stop - start) / step + 1 }, (pick, i) => start + i * step);
         setYears(range(currentYear, '2019', -1));
      })();
   }, []);

   const tabs = [
      {
         name: 'Weekly',
         value: 'weekly',
      },
      {
         name: 'Monthly',
         value: 'monthly',
      },
   ];

   const data = {
      labels:
         currentFilter === 'monthly'
            ? monthlyData.map(({ month }) => month)
            : weeklyData.map(({ day_name }) => day_name),
      datasets: [
         {
            backgroundColor: '#6CE8AB',
            hoverBackgroundColor: '#6CE8AB',
            data:
               currentFilter === 'monthly'
                  ? monthlyData.map(({ inflow }) => inflow)
                  : weeklyData.map(({ inflow }) => inflow),
         },
      ],
   };

   const getTotal = () => {
      let total = 0;

      total = (currentFilter === 'monthly' ? monthlyData : weeklyData).reduce(
         (a, b) => a + parseFloat(b.inflow),
         0,
      );

      return total.toLocaleString();
   };

   const fetchBusinessDetails = async () => {
      setLoading(true);
      if (businessAccountId) {
         try {
            const result = await getBusinessDetails(businessAccountId);
            const response = await getBusinessPcr(businessAccountId);
            setBizPcr(response.crs_score);
            if (result.ok) {
               setBizDetails(result.data);
               setError('');
            }
            setLoading(false);
         } catch (err) {
            setError(err.message || err.custom_message);
            setLoading(false);
         }
      }
   };

   const statusValue = (status) => {
      switch (status) {
         case null:
            return 'not uploaded';
         case 'pending':
            return 'uploaded';
         default:
            return status;
      }
   };

   async function logViewDetails() {
      try {
         await logActivity(`Viewed Business Details`, 'reg', businessAccountId);
      } catch (e) {
         toast.error(e.message);
      }
   }

   useEffect(() => {
      fetchBizMonthlyInflow();
      fetchBizWeeklyInflow();
   }, []);

   useEffect(() => {
      logViewDetails();
      fetchBusinessDetails();
   }, [businessAccountId]);

   const businessInfoProps = {
      bizDetails,
      businessAccountId,
      businessDetailId,
      fetchBusinessDetails,
      statusValue: (value) => statusValue(value),
      bizPcr,
      changePlanFocus,
      plans,
   };

   const ownerInfoProps = {
      bizDetails,
      businessAccountId,
      fetchBusinessDetails,
      statusValue: (value) => statusValue(value),
   };

   return (
      <OverviewWrapper>
         <AlertMessage message={error} variant="error" />

         {loading ? (
            <Spinner />
         ) : (
            <>
               <div className="pb-5">
                  <UserProfileComponent
                     businessAccountId={businessAccountId}
                     bizDetails={bizDetails}
                     fetchBusinessDetails={fetchBusinessDetails}
                     setLoading={(value) => setLoading(value)}
                     noCloseIcon={noCloseIcon}
                  />
               </div>

               <div className="custom-card">
                  <div className="d-flex justify-content-between align-items-center">
                     <div className="card-title">Summary</div>
                     <Summary
                        tabs={tabs}
                        currentFilter={currentFilter}
                        setCurrentFilter={setCurrentFilter}
                        getTotal={getTotal}
                        years={years}
                        selectedYear={selectedYear}
                        setSelectedYear={setSelectedYear}
                     />
                  </div>
                  <div className="card-value">₦{getTotal()}</div>
                  <div className="card-description">Total inflow</div>
                  <div className="chart-container">
                     <BarChart data={data} height={185} />
                  </div>
               </div>
               <div className="mb-4">
                  <Attention bizDetails={bizDetails} businessAccountId={businessAccountId} />
               </div>
               <ProgressReport bizDetails={bizDetails} businessAccountId={businessAccountId} />
               <BusinessInfo {...businessInfoProps} />
               <OwnerInfo {...ownerInfoProps} />

               {bizDetails?.board_partner_details?.length && (
                  <DirectorsInfo bizDetails={bizDetails} />
               )}
            </>
         )}
      </OverviewWrapper>
   );
};

Overview.propTypes = {
   businessAccountId: number.isRequired,
   businessDetailId: number.isRequired,
   noCloseIcon: bool.isRequired,
   changePlanFocus: func.isRequired,
   type: string.isRequired,
   plans: PropTypes.objectOf().isRequired,
};

export default Overview;
