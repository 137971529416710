import { useEffect, useState } from 'react';
import PropTypes, { string, func, bool } from 'prop-types';
import dayjs from 'dayjs';
import { REFERENCES, UNDER_REVIEW, approvalRequirements } from 'utils/approval-requirements';
import UserAvatar from 'assets/images/user.svg';
import DocumentViewer from 'components/document-previewer';
import { Button } from 'components/button';
import DocumentCard from 'components/document-card';
import { confirm } from 'components/confirm-box';
import ReferencesContainer from 'containers/business-details/new-documents/features/references';
import { AccountDetailsContainer, SuccessTag, ErrorTag, PendingTag, DefaultTag } from './style';

export default function PendingDocument2({
   business,
   accountDetails,
   businessType,
   currentTier,
   initializeBusiness,
   updateDocStatus,
   updating,
}) {
   const { business_type, references } = business || {};
   const [address, setAddress] = useState('');
   const [city, setCity] = useState('');
   const [region, setRegion] = useState('');
   const [activeDoc, setActiveDoc] = useState(null);
   const [docRequirements, setDocRequirements] = useState([]);
   const [allDocRequirements, setAllDocRequirements] = useState([]);

   const getReferenceStatus = (referenceList) => {
      if (referenceList && referenceList?.length) {
         if (referenceList.some((item) => item.status === UNDER_REVIEW)) return UNDER_REVIEW;
         return referenceList[0].status;
      }

      return null;
   };

   useEffect(() => {
      if (business_type && currentTier) {
         const getRequiredDoc = approvalRequirements[business_type][currentTier];

         const stream = getRequiredDoc?.map((item) => {
            const isReference = item.key === REFERENCES;
            return {
               ...item,
               status: isReference ? getReferenceStatus(business[item.key]) : business[item.key],
            };
         });
         const visibleStream = stream?.filter((item) => item.status === UNDER_REVIEW);
         setAllDocRequirements(stream);
         setDocRequirements(visibleStream);
         setActiveDoc(visibleStream[0]);
      }

      return null;
   }, [business_type, currentTier, business]);

   const handleNextDocument = ({ currentDoc }) => {
      const totalDocRequired = docRequirements.length;
      const currentDocIndex = docRequirements.map((e) => e.key).indexOf(currentDoc.key);

      // DOCUMENTS STILL LEFT IN THE STREAM
      if (currentDocIndex < totalDocRequired - 1) {
         const nextDocIndex = currentDocIndex + 1;
         const nextDoc = docRequirements[nextDocIndex];
         return { next: nextDoc };
      }

      return { next: null };
   };

   const documentNext = ({ status, currentDoc }) => {
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: async () => {
            try {
               if (activeDoc.key !== REFERENCES) {
                  await updateDocStatus({
                     payload: { [currentDoc.key]: status },
                     businessDetailId: business?.id,
                  });
               }
               const { next } = handleNextDocument({ currentDoc });
               window.scroll(0, 0);
               if (next) {
                  return setActiveDoc(next);
               }
               return initializeBusiness();
            } catch (e) {
               return e;
            }
         },
      });
   };

   useEffect(() => {
      setAddress(accountDetails?.biz_address);
      setCity(accountDetails?.biz_city);
      setRegion(accountDetails?.biz_state);
   }, [accountDetails]);

   const formatTag = (val, text) => {
      switch (val) {
         case 'under_review':
            return <PendingTag>{text || 'Under Review'} </PendingTag>;

         case 'accepted':
         case 'confirmed':
         case true:
            return <SuccessTag>{text || 'Verified'}</SuccessTag>;

         case 'rejected':
         case false:
            return <ErrorTag>{text || 'Rejected'}</ErrorTag>;

         case 'pending':
         case val === '':
         default:
            return <DefaultTag>{text || 'Not Uploaded'}</DefaultTag>;
      }
   };

   const renderPreview = () => {
      if (activeDoc) {
         if (activeDoc.key === REFERENCES) {
            return (
               <ReferencesContainer
                  references={references}
                  fetchBusinessDetails={initializeBusiness}
               />
            );
         }

         return (
            <DocumentViewer
               url={business[activeDoc?.url]}
               biz_account_id={business?.business_account}
               title={activeDoc?.name}
            >
               <div className="d-flex gap-3 pending-document-preview w-100">
                  <img src={business[activeDoc?.url]} alt={activeDoc.key} />
               </div>
            </DocumentViewer>
         );
      }

      return null;
   };

   const renderActionButtons = () => {
      const isReference = activeDoc?.key === REFERENCES;

      return (
         <div className="action-btns d-flex justify-content-md-end flex-wrap">
            <span className="me-4 mb-4 mb-md-0">
               <Button
                  type="submit"
                  variant="primary"
                  onClick={() => documentNext({ status: 'confirmed', currentDoc: activeDoc })}
                  isLoading={updating}
               >
                  {isReference ? 'Next Business' : `Approve ${activeDoc?.name}`}
               </Button>
            </span>

            {!isReference && (
               <span className="me-4 mb-4 mb-md-0">
                  <Button
                     type="submit"
                     variant="danger"
                     onClick={() => documentNext({ status: 'rejected', currentDoc: activeDoc })}
                     isLoading={updating}
                  >
                     Reject {activeDoc?.name}
                  </Button>
               </span>
            )}
         </div>
      );
   };

   if (!business || business.length < 1) return <h1 className="px-3">No Pending Document</h1>;

   return (
      <div>
         <AccountDetailsContainer className="px-md-5 py-5">
            <h3 className="current-document-name">{activeDoc?.name}</h3>
            {renderPreview()}

            <div className="user-profile">
               <div className="d-flex">
                  <img src={UserAvatar} className="user-image" alt="" />
                  <div className="">
                     <h2
                        className="user-name"
                        id="profile-user-name"
                     >{` ${business?.owner_details?.first_name} ${business?.owner_details?.last_name}`}</h2>
                     <p className="phone small-text mb-0 user-phoneno">
                        {business?.owner_details?.phone}
                     </p>
                     <div className="d-flex gap-3 email small-text mb-0 user-emailaddress">
                        {business?.owner_details?.email}
                        <div className="biz-status-badge">
                           {business?.business_status === 'unapproved'
                              ? formatTag('rejected', 'UNAPPROVED')
                              : formatTag('', 'PREAPPROVED')}
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            {businessType === 'registered' && (
               <div className="business-details">
                  <h3>Business Details</h3>
                  <div className="row">
                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Business Name</h6>
                           <p id="biz-name">{business?.business_name}</p>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Business Type</h6>
                           <p className="biz-type">{business?.business_type}</p>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>CAC Certificate Verified</h6>
                           <div id="cac-verification-status">{formatTag(business?.cac_status)}</div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Proof of Address</h6>
                           <div id="poa-status">{formatTag(business?.proof_of_address_status)}</div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Tax Identification Number</h6>
                           <div id="tax-identification-number">{accountDetails?.tin_number}</div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Tin Status</h6>
                           <div id="tin-status">{formatTag(business?.tin_status)}</div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Business Address</h6>
                           <div className="w-100">
                              <input
                                 id="biz-address"
                                 className="px-1"
                                 placeholder="Address"
                                 value={address}
                                 onChange={(e) => setAddress(e.target.value)}
                              />{' '}
                              <span className="mr-2"> , </span>
                              <input
                                 id="biz-city"
                                 className="mr-2"
                                 placeholder="City"
                                 value={city}
                                 onChange={(e) => setCity(e.target.value)}
                              />
                           </div>
                           <div className="d-flex mt-2">
                              <input
                                 id="biz-state"
                                 className="mr-2 px-1"
                                 placeholder="State"
                                 value={region}
                                 onChange={(e) => setRegion(e.target.value)}
                              />
                              {/* {detailsUpdateLoading ? (
                                 <Spinner />
                              ) : (
                                 <button
                                    type="button"
                                    className="bg-primary px-4 py-1 text-white update-biz-address"
                                    onClick={updateAddress}
                                 >
                                    Save
                                 </button>
                              )} */}
                           </div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Registered Business Address</h6>
                           <div id="reg_biz_address">
                              {business?.reg_biz_address
                                 ? `${accountDetails?.reg_biz_address}. ${accountDetails?.reg_biz_city}, ${accountDetails?.reg_biz_state}`
                                 : 'N/A'}
                           </div>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Business Registration Number</h6>
                           <p id="biz-cac-tag">{accountDetails?.rc_number}</p>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Turnover</h6>
                           <p id="biz-turnover">{accountDetails?.biz_turnover || 'N/A'}</p>
                        </div>
                     </div>

                     <div className="col-lg-3 col-md-6">
                        <div className="business-details-item">
                           <h6>Date Created</h6>
                           <div id="biz-datecreated">
                              {dayjs(business?.pub_date).format('MMM DD, YYYY')}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            )}

            <div className="business-details">
               <h3>{`${businessType === 'registered' ? 'Owner' : 'Account'} Information`}</h3>
               <div className="row">
                  <div className="col-lg-3 col-md-6">
                     <div className="business-details-item">
                        <h6>First Name</h6>
                        <p id="biz-name">{business?.owner_details?.first_name}</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                     <div className="business-details-item">
                        <h6>Last Name</h6>
                        <p id="biz-name">{business?.owner_details?.last_name}</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                     <div className="business-details-item">
                        <h6>Phone Number</h6>
                        <p id="biz-name">{business?.owner_details?.phone}</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                     <div className="business-details-item">
                        <h6>Email</h6>
                        <p id="biz-name">{business?.owner_details?.email}</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                     <div className="business-details-item">
                        <h6>BVN</h6>
                        <p id="biz-name">{formatTag(business?.owner_details?.bvn_status)}</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                     <div className="business-details-item">
                        <h6>NIN</h6>
                        <p id="biz-name">{formatTag(business?.owner_details?.nin_status)}</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                     <div className="business-details-item">
                        <h6>Indemnity</h6>
                        <p id="biz-name">{formatTag(business?.indemnity_signed)}</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className="business-details pt-4" id="documents-preview-container">
               <h3>Preview Documents</h3>
               <div id="preview-documents" className="row">
                  {allDocRequirements
                     ?.filter((item) => item.type === 'doc')
                     ?.map((item) => (
                        <div className="col-xl-3 col-lg-4 pr-0 col-md-6" key={item.key}>
                           <DocumentCard name={item.name} url={business[item.url]} />
                        </div>
                     ))}
               </div>
            </div>
            {renderActionButtons()}
         </AccountDetailsContainer>
      </div>
   );
}
PendingDocument2.propTypes = {
   business: PropTypes.objectOf().isRequired,
   accountDetails: PropTypes.objectOf().isRequired,
   currentTier: string.isRequired,
   businessType: string.isRequired,
   initializeBusiness: func.isRequired,
   updateDocStatus: func.isRequired,
   updating: bool.isRequired,
};
