import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { confirm } from 'components/confirm-box';
import PropTypes, { func } from 'prop-types';
import { Button } from 'components/button';
import { InfoGroup, Label, OverviewSection, Value } from 'styles/layout';
import { updateReference } from 'services/business';
import ReferenceInfoModal from './references-info-modal';

export default function ReferencesContainer({ references, fetchBusinessDetails }) {
   const [loading, setLoading] = useState(false);
   const [referenceInfoModal, setReferenceInfoModal] = useState(false);
   const [referenceDetails, setReferenceDetails] = useState({});
   const [referenceNo, setReferenceNo] = useState('');

   const handleClickInfo = (id, index) => {
      setReferenceInfoModal(true);
      setReferenceDetails(id);
      setReferenceNo(index + 1);
   };

   const handleApproveRejectReference = async (referenceId, type) => {
      setLoading(true);
      const payload = {
         status: type,
      };
      try {
         const res = await updateReference(referenceId, payload);
         toast.success(res.message || 'Operation successful');
         setReferenceInfoModal(false);
         if (fetchBusinessDetails && typeof fetchBusinessDetails === 'function')
            fetchBusinessDetails();
         setLoading(false);
      } catch (e) {
         toast.error(e?.message || 'Operation failed!');
         setLoading(false);
      }
   };

   const confirmSubmit = (referenceId, type) => {
      confirm({
         confirmText: 'Are you sure want to continue?',
         onConfirm: () => handleApproveRejectReference(referenceId, type),
      });
   };

   return (
      <>
         {references?.map((item, i) => (
            <div>
               {item.status !== 'confirmed' && (
                  <div key={uuidv4()}>
                     <h3 className="mb-5">Reference {i + 1}</h3>
                     <OverviewSection className="director-section">
                        <InfoGroup>
                           <Label>First Name</Label>
                           <Value>{item?.first_name || 'N/A'}</Value>
                        </InfoGroup>
                        <InfoGroup>
                           <Label>Last Name</Label>
                           <Value>{item?.last_name || 'N/A'}</Value>
                        </InfoGroup>
                        <InfoGroup>
                           <Label>Gender</Label>
                           <Value>{item?.gender || 'N/A'}</Value>
                        </InfoGroup>
                        <div>
                           <Button variant="secondary" onClick={() => handleClickInfo(item, i)}>
                              View Reference Information
                           </Button>
                        </div>
                        <InfoGroup>
                           <Label>Email</Label>
                           <Value>{item?.email || 'N/A'}</Value>
                        </InfoGroup>
                     </OverviewSection>
                  </div>
               )}
            </div>
         ))}
         {referenceInfoModal && (
            <ReferenceInfoModal
               open={referenceInfoModal}
               onClose={() => setReferenceInfoModal(false)}
               details={referenceDetails}
               referenceNo={referenceNo}
               onSubmit={confirmSubmit}
               loading={loading}
            />
         )}
      </>
   );
}

ReferencesContainer.propTypes = {
   references: PropTypes.arrayOf().isRequired,
   fetchBusinessDetails: func.isRequired,
};
