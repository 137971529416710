import Styled from 'styled-components';

export const SideBarWrapper = Styled.nav`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    overflow-x: scroll;
    transition:all ease .3s;
    @media (max-width:767px){
        left: ${(props) => (props.show ? '0' : '-100%')};
        background: #fff;
        z-index: 2000;
        top: 109px;
        width: 100%;
        padding-top:0;
    }

    .nav.sub-menu{
        padding-left:20px;
    }

    .arrow-icon {
                margin-right: 15px;
                transform: rotate(90deg);
            }

            .sidebar-links-arrow.sub-menu-open {
            }

            .arrow-icon.active {
                transform: rotate(270deg);

                svg {
                    path {
                        stroke: ${({ theme }) => theme.colors.primary};
                    }
                }
            }
`;

export const NavItemWrapper = Styled.div`
    transition:all ease .3s;
    :hover{
        background-color: var(--color-blue);
        .nav-link{
            color: #FFF;
        }
    }
    .nav-link {
        font-weight: 500;
        font-size:1.4rem;
        color: #333;
        text-transform: capitalize;
        padding: 12px;
        text-decoration:none;
    }
    .nav-link .feather {
        margin-right: 5px;
        color: #727272;
    }
    a.nav-link:hover {
        text-decoration: none!important;
    }
    .nav-pills .nav-link {
        color: #212529;
    }
    .nav-pills .nav-link .feather {
        margin-right: 4px;
    }
    .nav-link.active {
        color: #FFF;
        background-color: var(--color-blue);
    }
    .nav-link:hover .feather,
    .nav-link.active .feather {
        color: inherit;
    }
`;
