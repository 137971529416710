const FREELANCER = 'freelancer';
const REGISTERED = 'registered';
const TIER_3 = 'tier_3';
const TIER_4 = 'tier_4';
export const UNDER_REVIEW = 'under_review';
export const REFERENCES = 'references';

export const approvalRequirements = {
   [FREELANCER]: {
      [TIER_3]: [
         {
            name: 'Proof of Address',
            key: 'proof_of_address_status',
            url: 'proof_of_address_doc',
            type: 'doc',
         },
      ],
   },
   [REGISTERED]: {
      [TIER_3]: [
         {
            name: 'Proof of Address',
            key: 'proof_of_address_status',
            url: 'proof_of_address_doc',
            type: 'doc',
         },
      ],
      [TIER_4]: [
         {
            name: 'Status Report',
            key: 'status_report_state',
            url: 'status_report_doc',
            type: 'doc',
         },
         {
            name: 'Board of Resolution',
            key: 'board_resolution_status',
            url: 'board_resolution_doc',
            type: 'doc',
         },
         {
            name: 'References',
            key: REFERENCES,
            url: REFERENCES,
            type: REFERENCES,
         },
      ],
   },
};
