import { forwardRef, useCallback, useState, useMemo } from 'react';
import { ReactComponent as PasswordEyeInactive } from 'assets/images/eye-inactive.svg';
import { ReactComponent as PasswordEyeOpen } from 'assets/images/eye.svg';
import { bool, string, func } from 'prop-types';
import NumberFormat from 'react-number-format';
import {
   ErrorP,
   PasswordControlButton,
   AdornmentWrapperDiv,
   Div,
   InputField,
   Label,
   InputFieldWrapperDiv,
   NumberInputField,
} from './input.style';

const Input = forwardRef(
   (
      {
         id,
         type = 'text',
         label,
         required,
         value,
         error,
         fullWidth,
         className,
         onChange,
         startAdornment,
         endAdornment,
         ...remainingProps
      },
      ref,
   ) => {
      const labelId = label ? `${id}` : undefined;
      const errorId = error ? `${id}-error-text` : undefined;

      const [showPassword, setShowPassword] = useState(false);

      const handleChange = useCallback(
         (event) => {
            onChange?.(event);
         },
         [onChange],
      );

      const PasswordControl = useMemo(
         () => (
            <PasswordControlButton type="button" onClick={() => setShowPassword(!showPassword)}>
               {showPassword ? (
                  <PasswordEyeOpen className="eye-icon" width="18" />
               ) : (
                  <PasswordEyeInactive width="18" className="eye-icon" />
               )}
            </PasswordControlButton>
         ),
         [showPassword],
      );

      return (
         <Div fullWidth={fullWidth} className={className}>
            {label && (
               <Label id={labelId} htmlFor={id}>
                  {`${label}${required ? '*' : ''}`}
               </Label>
            )}

            <InputFieldWrapperDiv>
               {startAdornment && <AdornmentWrapperDiv>{startAdornment}</AdornmentWrapperDiv>}
               <InputField
                  id={id}
                  ref={ref}
                  type={showPassword ? 'text' : type}
                  value={value}
                  required={required}
                  aria-invalid={!!error}
                  aria-describedby={errorId}
                  onChange={handleChange}
                  error={!!error}
                  startAdornment={startAdornment}
                  {...remainingProps}
               />
               {endAdornment && (
                  <AdornmentWrapperDiv className="end">
                     {type === 'password' ? PasswordControl : endAdornment}
                  </AdornmentWrapperDiv>
               )}
            </InputFieldWrapperDiv>

            {error && (
               <ErrorP
                  initial="pageInitial"
                  animate="pageAnimate"
                  exit="pageExit"
                  variants={{
                     pageInitial: {
                        opacity: 1,
                        x: 0,
                        y: -5,
                     },
                     pageAnimate: {
                        opacity: 1,
                        x: 0,
                        y: 0,
                     },
                     pageExit: {
                        opacity: 0,
                     },
                  }}
                  id={errorId}
               >
                  {error}
               </ErrorP>
            )}
         </Div>
      );
   },
);

const NumberInput = forwardRef(
   ({
      id,
      label,
      name,
      required,
      value,
      error,
      fullWidth,
      className,
      onChange,
      startAdornment,
      // endAdornment,
      prefix,
      ...rest
   }) =>
      // ref,
      {
         const labelId = label ? `${id}` : undefined;
         const errorId = error ? `${id}-error-text` : undefined;

         return (
            <Div fullWidth={fullWidth} className={className}>
               {label && (
                  <Label id={labelId} htmlFor={id}>
                     {`${label}${required ? '*' : ''}`}
                  </Label>
               )}

               <InputFieldWrapperDiv>
                  {startAdornment && <AdornmentWrapperDiv>{startAdornment}</AdornmentWrapperDiv>}
                  <NumberInputField>
                     <NumberFormat
                        value={value}
                        name={name}
                        required={required}
                        aria-invalid={!!error}
                        aria-describedby={errorId}
                        placeholder={label}
                        thousandSeparator
                        {...(prefix !== 'hidden' && { prefix: prefix || '₦' })}
                        className="number-format"
                        inputmode="numeric"
                        onValueChange={onChange}
                        {...rest}
                     />
                  </NumberInputField>
               </InputFieldWrapperDiv>

               {error && (
                  <ErrorP
                     initial="pageInitial"
                     animate="pageAnimate"
                     exit="pageExit"
                     variants={{
                        pageInitial: {
                           opacity: 1,
                           x: 0,
                           y: -5,
                        },
                        pageAnimate: {
                           opacity: 1,
                           x: 0,
                           y: 0,
                        },
                        pageExit: {
                           opacity: 0,
                        },
                     }}
                     id={errorId}
                  >
                     {error}
                  </ErrorP>
               )}
            </Div>
         );
      },
);

Input.displayName = 'input';

Input.propTypes = {
   isLoading: bool,
   type: string,
   fullWidth: bool,
   name: string,
   id: string.isRequired,
   label: string,
   onChange: func.isRequired,
   className: string.isRequired,
   value: string.isRequired,
   error: string,
   required: bool,
   startAdornment: bool,
   endAdornment: bool,
};

Input.defaultProps = {
   isLoading: false,
   required: false,
   fullWidth: false,
   type: 'text',
   startAdornment: false,
   endAdornment: false,
   name: '',
   label: '',
   error: '',
};

NumberInput.propTypes = {
   // isLoading: bool,
   // type: string,
   fullWidth: bool,
   name: string,
   id: string.isRequired,
   label: string,
   onChange: func.isRequired,
   className: string.isRequired,
   value: string.isRequired,
   error: string,
   required: bool,
   startAdornment: bool,
   // endAdornment: bool,
   prefix: string,
};

NumberInput.defaultProps = {
   // isLoading: false,
   required: false,
   fullWidth: false,
   // type: 'text',
   startAdornment: false,
   // endAdornment: false,
   name: '',
   label: '',
   error: '',
   prefix: '',
};

export { Input, NumberInput };
