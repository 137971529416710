import { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';
import { useAuth } from 'hooks/auth';
import { hasValue } from 'utils/validations';
import { ticketStatus } from 'utils/ticketingConstants';
import { hasPermission } from 'utils/user_access';
import { MoreHoriz, MenuOutlined } from '@material-ui/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { TooltipComponent } from 'components/tooltip';
import { MenuComponent } from 'components/menu';
import TabMenu from 'components/TabMenu';
import { StatsCards } from 'components/stats-cards';
import { TableComponent } from 'components/table';
import { Button } from 'components/button';
import { TableContainer, TableContent } from 'styles/table';
import { TicketStatus } from 'styles/utils';
import { HeadingOne } from 'styles/typography';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { TabWrapper } from 'pages/pending-documents/style';
import { getAllTickets, getTicketStats } from 'services/ticketing';
import TicketFilterModal from './features/ticket-filter-modal';
import TicketDetails from './features/ticket-details';
import TicketCategory from './features/ticket-category';
import CreateTicket from './features/create-ticket';
import PerformanceOverview from './features/performance-overview';
import BulkAssignTicketsComponent from './features/bulk-assign';

const Ticketing = () => {
   const { currentUser } = useAuth();
   const { permissions } = currentUser?.access || {};
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const history = useHistory();
   const [searchTerm, setSearchTerm] = useState('');
   const [tickets, setTickets] = useState([]);
   const [filterVisibility, setFilterVisibility] = useState(false);
   const [ticketCategoryVisibility, setTicketCategoryVisibility] = useState(false);
   const [isLoadingMangers, setIsLoadingMangers] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [ticketId, setTicketId] = useState('');
   const [actionAnchorEl, setActionsAnchorEl] = useState(null);
   const openActionsMenu = Boolean(actionAnchorEl);
   const [bulkAssignActive, setBulkAssignActive] = useState(false);
   const [selectionModel, setSelectionModel] = useState([]);
   const [assignTicketsModalVisibility, setAssignTicketsModalVisibility] = useState(false);
   const [ticketDetailsVisibility, setTicketDetailsVisibility] = useState(false);
   const [createTicketVisibility, setCreateTicketVisibility] = useState(false);
   const [activeTab, setActiveTab] = useState('new');
   const [statsData, setStatsData] = useState({});
   const [performance, setPerformance] = useState([]);
   const [anchorEl, setAnchorEl] = useState(null);
   const [filterData, setFilterData] = useState({});
   const [filterPayload, setFilterPayload] = useState({
      issue_status: 'new',
   });
   const open = Boolean(anchorEl);

   const options = ['View Details'];
   const actionOptions = ['Bulk Assign Tickets'];

   const admin = hasPermission(permissions, 'ticket_admin');

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }
   const debounce = useDebouncedCallback(handleSearch, 3000);

   const handleActionClick = (event) => {
      setActionsAnchorEl(event.currentTarget);
   };

   function handleBulkAssign() {
      setBulkAssignActive(!bulkAssignActive);
   }

   const handleCloseActions = () => {
      setActionsAnchorEl(null);
   };

   const fetchAllTickets = async (backgroundLoad) => {
      const { assigned_to, issue_status } = filterPayload;
      let assigned = null;

      if (!admin && issue_status && issue_status !== 'new') {
         assigned = currentUser?.prospa_user_id;
      } else if (!admin && !issue_status) {
         assigned = currentUser?.prospa_user_id;
      } else if (assigned_to) {
         assigned = assigned_to;
      }

      try {
         if (!backgroundLoad) {
            setIsLoadingMangers(true);
            setTickets([]);
            setPaginationData({});
         }

         const response = await getAllTickets({
            page,
            ...filterPayload,
            ...(hasValue(assigned) && { assigned_to: assigned }),
            ...(hasValue(searchTerm) && { biz_name: searchTerm }),
         });
         setTickets(response.results);
         setPaginationData(response);

         const statResponse = await getTicketStats({
            ...filterPayload,
            ...(!admin && { assigned_to: currentUser?.prospa_user_id }),
            ...(hasValue(assigned) && { assigned_to: assigned }),
         });
         let stats = {
            total: 0,
            closed: '',
            new: '',
            on_hold: '',
            open: '',
            pending: '',
         };
         setPerformance(statResponse.stats_by_assigned_to);
         statResponse.stats_by_issue_status?.forEach(({ issue_status: title, total }) => {
            stats = {
               ...stats,
               total: stats.total + total,
               [title]: total,
            };
         });
         setStatsData(stats);
      } catch (err) {
         return err;
      } finally {
         setIsLoadingMangers(false);
      }
   };

   useEffect(() => {
      if (currentUser?.prospa_user_id) {
         fetchAllTickets();
      }
   }, [searchTerm, page, filterPayload, currentUser]);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   function handleOpenModal() {
      setTicketDetailsVisibility(true);
   }

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const handleTabSwitch = (value) => {
      history.push(`${history.location.pathname}?page=1`);
      setActiveTab(value);
      if (value !== 'performance') {
         setFilterPayload({
            ...filterPayload,
            issue_status: value !== 'all' ? value : null,
         });
      }
   };

   useEffect(() => {}, [debounce]);

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => handleOpenModal(row.id),
                  ]}
               />
            </div>
         ),
      },
      {
         field: 'business_name',
         headerName: 'Business Name',
         width: 300,
         renderCell: ({ row }) => (
            <TooltipComponent className="m-0" title={`${row.business_name}`}>
               <div className="d-flex align-items-center">
                  <p
                     style={{
                        cursor: 'pointer',
                     }}
                     className="text-primary w-100"
                     onClick={() => handleOpenModal(row.id)}
                  >
                     {`${row.business_name}` || 'N/A'}{' '}
                  </p>
                  {row.notification ? (
                     <span className="ms-3">
                        <TicketStatus className="m-0" status="light-pink">
                           {row.notification} messages
                        </TicketStatus>
                     </span>
                  ) : null}
               </div>
            </TooltipComponent>
         ),
      },
      {
         field: 'created_by_name',
         headerName: 'Created By',
         renderCell: ({ row }) => <span>{row?.created_by_name}</span>,
      },
      {
         field: 'issue_status',
         headerName: 'Ticket Status',
         renderCell: ({ row }) => (
            <TicketStatus className="m-0" status={row?.issue_status}>
               {row?.issue_status}
            </TicketStatus>
         ),
      },
      {
         field: 'created',
         headerName: 'Date Submitted',
         renderCell: ({ row }) => (
            <span>{moment(row?.created).format('MMM DD, YYYY | h:mm A')}</span>
         ),
      },
      {
         field: 'assigned_to_name',
         headerName: 'Manager',
         renderCell: ({ row }) => <span>{row?.assigned_to_name || 'Unassigned'}</span>,
      },
      {
         field: 'category.category',
         headerName: 'Category',
         renderCell: ({ row }) => <span>{row?.category?.category || 'Unassigned'}</span>,
      },
      {
         field: 'id',
         headerName: 'Ticket ID',
         renderCell: ({ row }) => <span> #{row?.id}</span>,
      },
      {
         field: 'hbizaccount',
         headerName: 'Business ID',
         renderCell: ({ row }) => <span>{row.hbizaccount}</span>,
      },
      {
         field: 'service_feedback',
         headerName: 'Member satisfaction rating',
         renderCell: ({ row }) => <span>{row?.service_feedback?.stars || 'N/A'}</span>,
      },
   ];

   function onRowClick({ row }) {
      setTicketId(row.id);
   }

   const onCloseFilter = () => {
      setFilterVisibility(false);
   };

   const filterIconProps = {
      className: 'me-3',
      style: { width: 'fit-content', minWidth: '136px' },
   };

   const tabs = [...ticketStatus];
   tabs?.splice(1, 0, {
      label: 'All',
      value: 'all',
   });

   return (
      <LoggedInDashboard>
         <HeadingOne className="mb-5">Tickets </HeadingOne>

         <StatsCards data={statsData} />

         <TableContent
            className="d-flex justify-content-between align-items-center mb-4"
            noBorderTop
            noBorderBottom
         >
            <div>
               <h1> Tickets</h1>
               <span className="value">{paginationData?.count || 0}</span>
            </div>

            {hasPermission(permissions, 'create_ticket') && (
               <Button variant="secondary" onClick={() => setCreateTicketVisibility(true)}>
                  Create Ticket
               </Button>
            )}
         </TableContent>

         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               {tabs.map(({ label, value }) => (
                  <TabMenu
                     key={value}
                     text={label}
                     active={activeTab === value}
                     setActiveTab={() => handleTabSwitch(value)}
                  />
               ))}

               {admin && (
                  <TabMenu
                     text="Performance Overview"
                     active={activeTab === 'performance'}
                     setActiveTab={() => handleTabSwitch('performance')}
                  />
               )}
            </ul>
         </TabWrapper>

         {activeTab !== 'performance' && (
            <>
               <TableContainer id="table">
                  <TableContent noBorderTop>
                     <div className="d-flex justify-content-between align-items-center">
                        <FilterContainer
                           {...filterIconProps}
                           onClick={() => setFilterVisibility(true)}
                        >
                           Filter By
                           <FilterListIcon style={{ marginLeft: '10px' }} />
                        </FilterContainer>
                     </div>
                     <div className="d-flex gap-3">
                        {(hasPermission(permissions, 'add_ticket_category') ||
                           hasPermission(permissions, 'update_delete_ticket_category')) && (
                           <Button onClick={() => setTicketCategoryVisibility(true)}>
                              Update Ticket categories
                           </Button>
                        )}
                        {bulkAssignActive && selectionModel.length > 0 ? (
                           <Button onClick={() => setAssignTicketsModalVisibility(true)}>
                              Bulk Assign
                           </Button>
                        ) : (
                           <div className="w-100">
                              <div>
                                 <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handleActionClick}
                                 >
                                    <MenuOutlined />
                                 </IconButton>
                                 <MenuComponent
                                    anchorEl={actionAnchorEl}
                                    handleClose={handleCloseActions}
                                    open={openActionsMenu}
                                    options={actionOptions}
                                    optionsFns={[
                                       () => handleBulkAssign(),
                                       // place the callbacks in position corresponding to the options index
                                    ]}
                                 />
                              </div>
                           </div>
                        )}
                     </div>
                  </TableContent>
                  <TableComponent
                     columns={columns}
                     rows={tickets}
                     isLoading={isLoadingMangers}
                     onPageChange={onPageChange}
                     count={Math.ceil(paginationData.count / 15)}
                     page={currentPage}
                     onRowClick={onRowClick}
                     checkboxSelection={bulkAssignActive}
                     onSelectionModelChange={setSelectionModel}
                  />
               </TableContainer>
            </>
         )}

         {activeTab === 'performance' && <PerformanceOverview data={performance} />}

         {filterVisibility && (
            <TicketFilterModal
               open={filterVisibility}
               onClose={onCloseFilter}
               filterData={filterData}
               setFilterData={setFilterData}
               setFilterPayload={setFilterPayload}
               setCurrentPage={setCurrentPage}
               filterPayload={filterPayload}
            />
         )}

         {ticketDetailsVisibility && (
            <TicketDetails
               open={ticketDetailsVisibility}
               ticketId={ticketId}
               onClose={() => setTicketDetailsVisibility(false)}
               filterData={filterData}
               setFilterData={setFilterData}
               setFilterPayload={setFilterPayload}
               setCurrentPage={setCurrentPage}
               fetchAllTickets={fetchAllTickets}
            />
         )}

         {ticketCategoryVisibility && (
            <TicketCategory
               open={ticketCategoryVisibility}
               onClose={() => setTicketCategoryVisibility(false)}
            />
         )}

         {createTicketVisibility && (
            <CreateTicket
               open={createTicketVisibility}
               onClose={() => setCreateTicketVisibility(false)}
               fetchAllTickets={fetchAllTickets}
            />
         )}
         {assignTicketsModalVisibility && (
            <BulkAssignTicketsComponent
               open={assignTicketsModalVisibility}
               onClose={() => setAssignTicketsModalVisibility(false)}
               ticketIds={selectionModel}
               fetchAllTickets={fetchAllTickets}
            />
         )}
      </LoggedInDashboard>
   );
};

export default Ticketing;
