import { useState, useEffect } from 'react';
import Modal from 'components/modal';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import {
   feedWorkerTracker,
   getFollowUpFeed,
   getActivationFeed,
   assignActivationManager,
} from 'services/business';
import { getBillingPlans } from 'services/new-billing';
import { verifySecurityQuestion, getBusinessDetails } from 'services/businessDetails';
import { confirm } from 'components/confirm-box';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { useAuth } from 'hooks/auth';
import moment from 'moment';
import { Spinner } from 'components/spinner';
import RequestedLoans from 'containers/loan-details/requested-loans';
import toast from 'react-hot-toast';
import PropTypes, { bool, func, number, string, objectOf } from 'prop-types';
import { Navigation, NavigationTab } from 'styles/layout';
import { businessModalTab, questionAndAnswer } from 'utils/business';
import Log from './log';
import Notes from './notes';
import Overview from './overview';
import SendMessage from './sendMessage';
import Transactions from './transactions';
import Accounts from './accounts';
import CardsTab from './prospa-cards';
import Settings from './settings';
import QualifyingInformation from './qualifying-information';
import ChangePlanNewBilling from './change-plan/new-billing';
import NewDocuments from './new-documents';

const BusinessDetails = ({
   open,
   onClose,
   businessAccountId,
   businessDetailId,
   type,
   noCloseIcon,
   dateRange,
   streamType,
}) => {
   const { state, setActiveTab } = useBizDetailsModal();
   const { currentUser } = useAuth();
   const { activeTab } = state;
   const [newStream, setNewStream] = useState(null);
   const [newDetailId, setNewDetailId] = useState(null);
   const [isLoadingBusiness, setIsLoadingNextBusiness] = useState(null);
   const [, setActiveQuestion] = useState('');
   const [questionsToAnswer, setQuestionsToAnswer] = useState([]);
   const [value, setValue] = useState('');
   const [securityModal, setSecurityModal] = useState(true);
   const [bizDetails, setBizDetails] = useState('');
   const [verifyingSecurityQuestion, setVerifyingSecurityQuestion] = useState(false);
   const [loading, setLoading] = useState(true);
   const [, setError] = useState('');
   const [tabs, setTabs] = useState([]);
   const { permissions } = currentUser?.access || {};
   const [focus, setFocus] = useState('business-details');
   const [plans, setPlans] = useState([[]]);

   const action = 'read_business_details';

   useEffect(() => {
      if (!permissions?.includes(action) && permissions !== 'all' && open) {
         toast.error(`You are not authorized to perform this action: ${action}`);
         return onClose();
      }
   }, [open]);

   useEffect(() => {
      if (permissions) {
         setTabs(businessModalTab(permissions));
      }
   }, [permissions]);

   const fetchPlans = async () => {
      try {
         const response = await getBillingPlans();
         setPlans(response.results);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   useEffect(() => {
      fetchPlans();
   }, []);

   const overviewProps = {
      businessAccountId: newStream || businessAccountId,
      businessDetailId: businessDetailId || newDetailId,
      noCloseIcon,
      changePlanFocus: () => setFocus('change-plan'),
      type,
      plans,
   };

   const activePage = () => {
      switch (activeTab) {
         case 'notes':
            return <Notes businessAccountId={newStream || businessAccountId} />;

         case 'log':
            return <Log businessAccountId={newStream || businessAccountId} />;

         case 'documents':
            return (
               <NewDocuments
                  businessAccountId={newStream || businessAccountId}
                  businessDetailId={businessDetailId || newDetailId}
               />
            );

         case 'message':
            return <SendMessage businessAccountId={newStream || businessAccountId} />;

         case 'transactions':
            return <Transactions businessAccountId={newStream || businessAccountId} />;

         case 'accounts':
            return (
               <Accounts
                  businessAccountId={newStream || businessAccountId}
                  userData={currentUser}
               />
            );

         case 'cards':
            return <CardsTab bizDetails={bizDetails} />;

         case 'requested-loans':
            return <RequestedLoans businessAccountId={businessAccountId} />;

         case 'settings':
            return <Settings businessAccountId={newStream || businessAccountId} />;

         case 'qualifying-information':
            return <QualifyingInformation businessAccountId={newStream || businessAccountId} />;

         case '':
         case 'overview':
         default:
            return <Overview {...overviewProps} />;
      }
   };

   const handleEndWork = () => {
      confirm({
         confirmText: 'Are you sure you want to end work?',
         isLoading: false,
         onConfirm: async () => {
            try {
               setNewStream(null);
               toast.success('You have successfully ended work');
               onClose();
            } catch ({ message }) {
               toast.error(message);
            }
         },
      });
   };

   const handleNextBusiness = () => {
      confirm({
         confirmText: ' Have you contacted the business owner?',
         isLoading: false,
         multiple: true,
         onConfirm: () => {
            confirm({
               confirmText: 'Have you logged notes?',
               onConfirm: async () => {
                  setIsLoadingNextBusiness(true);
                  try {
                     // const user = localStorage.getItem('prospa-user-data').prospa_user_id;
                     // console.log(user)
                     const payload = {
                        prospa_manager_id: currentUser.prospa_user_id,
                        biz_account_id: newStream || businessAccountId,
                        action_type: 'single',
                        stream_type: streamType,
                     };
                     const result = await feedWorkerTracker(payload, streamType);
                     toast.success(result.message);
                     let stream = '';
                     if (streamType === 'work_followup') {
                        stream = await getFollowUpFeed(dateRange);
                     } else if (streamType === 'work_activation') {
                        stream = await getActivationFeed(dateRange);
                     }
                     if (!stream.data) {
                        setIsLoadingNextBusiness(false);
                        return toast.error("There's no account left on this stream");
                     }
                     setNewStream(stream.data.biz_account_id);
                     // setAccountId(stream.data.biz_account_id);
                     setActiveTab('overview');
                     // onClose();
                  } catch ({ message }) {
                     toast.error(message);
                  }
                  setIsLoadingNextBusiness(false);
               },
            });
         },
      });
   };

   const fetchBusinessDetails = async () => {
      try {
         const result = await getBusinessDetails(businessAccountId);
         if (result.ok) {
            setBizDetails(result.data);
            setNewDetailId(result?.data?.business_detail?.id);
            setError('');
         }

         setLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setLoading(false);
      }
   };

   const handleAssignActivationManager = async () => {
      try {
         await assignActivationManager(businessAccountId);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   useEffect(() => {
      if (type === 'activation' && open) {
         // CHECK IF BUSINESS HAS ACTIVATION MANAGER
         if (
            bizDetails &&
            !bizDetails?.assigned_manager_details &&
            permissions?.includes('activation_manager')
         ) {
            // ASSIGN ACTIVATION MANAGER
            handleAssignActivationManager();
         }
      }
   }, [bizDetails, open, permissions]);

   const getQuestionsIndex = () => {
      const arr = [];
      while (arr.length < 2) {
         const r = Math.floor(Math.random() * 4) + 1;
         if (arr.indexOf(r) === -1) arr.push(r);
      }
      setQuestionsToAnswer(arr);
      setActiveQuestion(arr[0]);
   };

   const renderQuestions = () => {
      const index = questionsToAnswer[0] ? questionsToAnswer[0] - 1 : questionsToAnswer - 1;
      return (
         <div>
            <Input
               placeholder={questionAndAnswer[index]?.question}
               label={questionAndAnswer[index]?.question}
               name={questionAndAnswer[index]?.type}
               type={questionAndAnswer[index]?.inputType}
               value={
                  questionAndAnswer[index]?.defaultValue
                     ? questionAndAnswer[index].defaultValue + value
                     : value
               }
               onChange={(e) => {
                  if (questionAndAnswer[index].defaultValue) {
                     if (e.target.value.length < questionAndAnswer[index].defaultValue.length) {
                        return setValue('');
                     }
                     const ret = e.target.value
                        .toString()
                        .replace(questionAndAnswer[index].defaultValue, '');
                     setValue(ret);
                  } else {
                     setValue(e.target.value);
                  }
               }}
            />
         </div>
      );
   };
   useEffect(() => {
      (async function init() {
         await fetchBusinessDetails();
         getQuestionsIndex();
      })();
   }, [bizDetails && bizDetails.biz_account_id, open]);

   const handleQuestionSubmit = async () => {
      if (!value || value === '') return toast.error('Please enter a valid answer');
      setVerifyingSecurityQuestion(true);
      const index = questionsToAnswer[0] ? questionsToAnswer[0] - 1 : questionsToAnswer - 1;
      const question = questionAndAnswer[index];
      try {
         const params = {
            biz_account_id: businessAccountId,
            [question.type]: `${
               question.type === 'phone_number' ? `+${question.defaultValue}${value}` : value
            }`,
         };
         const response = await verifySecurityQuestion(params);
         if (response.final_check) {
            setValue('');
            // Move to the second question
            if (Array.isArray(questionsToAnswer)) {
               const arr = questionsToAnswer;
               const modifiedArr = arr.pop();
               setQuestionsToAnswer(modifiedArr);
               toast.success('Correct, please answer this final question.');
            } else {
               // BOTH SECURITY QUESTIONS PASSED
               setSecurityModal(false);
            }
         } else {
            toast.error('Invalid answer provided, you need to provide 2 correct answers at a go.');
            setValue('');
            getQuestionsIndex();
         }
      } catch (e) {
         toast.error(e.message);
      }
      setVerifyingSecurityQuestion(false);
   };

   if (loading) return <Spinner />;

   if (
      !permissions?.includes('read_business_without_security') &&
      permissions !== 'all' &&
      bizDetails.biz_status === 'approved' &&
      securityModal
   )
      return (
         <Modal
            open={open}
            onClose={() => {
               onClose();
               setActiveTab(null);
            }}
            closeOnOverlayClick={false}
            noCloseIcon={noCloseIcon}
            title="Security Questions"
            className="biz-security-question-modal"
         >
            <form onSubmit={handleQuestionSubmit}>
               <div className="pt-4 mb-4">{renderQuestions()}</div>

               <div>
                  <Button
                     variant="primary"
                     onClick={handleQuestionSubmit}
                     isLoading={verifyingSecurityQuestion}
                     disabled={!value || value === ''}
                  >
                     Submit Answer
                  </Button>
               </div>
            </form>
         </Modal>
      );

   if (focus === 'business-details')
      return (
         <Modal
            open={open}
            onClose={() => {
               onClose();
               setActiveTab(null);
            }}
            closeOnOverlayClick={false}
            noCloseIcon={noCloseIcon}
            title="Business Account Details"
            className="biz-details-modal"
         >
            <div className="d-flex justify-content-between">
               <Navigation>
                  {tabs?.map(({ label, value: val }) => (
                     <NavigationTab
                        className={activeTab === val ? 'active' : ''}
                        onClick={() => setActiveTab(val)}
                        key={val}
                     >
                        {label}
                     </NavigationTab>
                  ))}
               </Navigation>

               {noCloseIcon && (
                  <div className="d-flex align-items-end">
                     <Button variant="primary" className="me-4" onClick={handleNextBusiness}>
                        Next Business
                     </Button>
                     <Button variant="danger" onClick={handleEndWork}>
                        End Work
                     </Button>
                  </div>
               )}
            </div>
            <div className="pt-5">{!isLoadingBusiness ? activePage() : <Spinner />}</div>
         </Modal>
      );

   if (focus === 'change-plan')
      return (
         <ChangePlanNewBilling
            open={open}
            bizDetails={bizDetails}
            plans={plans}
            onClose={() => setFocus('business-details')}
         />
      );
};

BusinessDetails.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   businessAccountId: number.isRequired,
   businessDetailId: number.isRequired,
   type: string,
   dateRange: objectOf(string),
   noCloseIcon: bool,
   streamType: string.isRequired,
   plans: PropTypes.objectOf().isRequired,
};
BusinessDetails.defaultProps = {
   type: '',
   dateRange: {
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
   },
   noCloseIcon: false,
};
export default BusinessDetails;
