import { useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { func, bool, arrayOf, objectOf, string } from 'prop-types';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { InputWrapper } from 'styles/form';

const documentStatusOptions = [
   {
      value: 'pending',
      label: 'Pending',
   },
   {
      value: 'confirmed',
      label: 'Verified',
   },
   {
      value: 'confirmed_manual',
      label: 'Verified Manually',
   },
   {
      value: 'failed_auto_verify',
      label: 'Failed',
   },
   {
      value: 'rejected',
      label: 'Rejected',
   },
];

const indemnityStatus = [
   {
      value: true,
      label: 'Signed',
   },
   {
      value: false,
      label: 'Pending',
   },
];

const BusinessAccountsFilerModal = ({
   open,
   onClose,
   setCurrentPage,
   filterData,
   setFilterData,
   setFilterPayload,
}) => {
   const history = useHistory();
   const [isLoading, setIsLoading] = useState(false);
   const [isClearingFilter, setIsClearingFilter] = useState(false);

   function onChange(name, data) {
      setFilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      setIsLoading(true);
      try {
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);
         setFilterPayload(filterData);
         onClose();
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setIsLoading(false);
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   async function handleClearFilter() {
      setIsClearingFilter(true);
      try {
         setCurrentPage(() => 1);
         history.push(`${history.location.pathname}?page=1`);
         setFilterData({});
         setFilterPayload({});
         onClose();
      } catch (err) {
         return err;
      }
      setIsClearingFilter(false);
   }

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="created_after"
                  type="date"
                  value={filterData?.created_after}
                  onChange={(e) => {
                     setFilterData({ ...filterData, created_after: e.target.value });
                  }}
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="created_before"
                  type="date"
                  value={filterData?.created_before}
                  onChange={(e) => {
                     setFilterData({ ...filterData, created_before: e.target.value });
                  }}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  name="bvn_status"
                  label="BVN"
                  options={documentStatusOptions}
                  value={documentStatusOptions.filter(
                     (item) => item.value === filterData?.bvn_status,
                  )}
                  onChange={(val) => onChange('bvn_status', val)}
               />

               <SelectInput
                  name="nin_status"
                  label="NIN"
                  options={documentStatusOptions}
                  value={documentStatusOptions.filter(
                     (item) => item.value === filterData?.nin_status,
                  )}
                  onChange={(val) => onChange('nin_status', val)}
               />
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <SelectInput
                  name="selfie_status"
                  label="Selfie"
                  options={documentStatusOptions}
                  value={documentStatusOptions.filter(
                     (item) => item.value === filterData?.selfie_status,
                  )}
                  onChange={(val) => onChange('selfie_status', val)}
               />

               <SelectInput
                  name="proof_of_address_status"
                  label="Proof of Address"
                  options={documentStatusOptions}
                  value={documentStatusOptions.filter(
                     (item) => item.value === filterData?.proof_of_address_status,
                  )}
                  onChange={(val) => onChange('proof_of_address_status', val)}
               />
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <SelectInput
                  name="indemnity_signed"
                  label="Indemnity"
                  options={indemnityStatus}
                  value={indemnityStatus.filter(
                     (item) => item.value === filterData?.indemnity_signed,
                  )}
                  onChange={(val) => onChange('indemnity_signed', val)}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={handleClearFilter}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export { BusinessAccountsFilerModal };

BusinessAccountsFilerModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   setCurrentPage: func.isRequired,
   filterData: arrayOf(objectOf(string)).isRequired,
   setFilterData: func.isRequired,
   setFilterPayload: func.isRequired,
};
