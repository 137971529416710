/* eslint-disable no-restricted-globals */
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { string } from 'prop-types';
import { fetchBusinessForApprovals, updateBusinessAccountApi } from 'services/business';
import LoggedInDashboard from 'components/logged-in-dashboard';
import ApproverStats from 'containers/approver-stats';
import moment from 'moment';
import PendingDocument2 from 'components/pending-document/pending-document-2';
import Notes from 'containers/business-details/notes';
import { HeadingOne } from 'styles/typography';
import { useHistory, useLocation } from 'react-router-dom';
import { getBusinessDetails } from 'services/businessDetails';
import { Button } from 'components/button';
import { Spinner } from 'components/spinner';
import { TabWrapper } from './style';

const getDates = (value) => {
   if (value === 'last-24') {
      return {
         created_after: moment().subtract(1, 'days').format('YYYY-MM-DD'),
         created_before: moment().format('YYYY-MM-DD'),
      };
   }
   if (value === 'last-7') {
      return {
         created_after: moment().subtract(9, 'days').format('YYYY-MM-DD'),
         created_before: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      };
   }
   return {
      created_after: '2019-01-01',
      created_before: moment().format('YYYY-MM-DD'),
   };
};

export default function ApproverContainer({ businessType }) {
   const { search } = useLocation();
   const activeTabInUrl = new URLSearchParams(search).get('active');
   const [accountDetails, setAccountDetails] = useState({});
   const [activeTab, setActiveTab] = useState('overview');
   const [business, setBusiness] = useState(null);
   const [filter, setFilter] = useState(activeTabInUrl || 'all');
   const [currentTier, setCurrentTier] = useState('tier_3');
   const [startDate, setStartDate] = useState(
      activeTabInUrl ? getDates(activeTabInUrl).created_after : '2019-01-01',
   );
   const [loading, setLoading] = useState(false);
   const [updating, setUpdating] = useState(false);
   const [endDate, setEndDate] = useState(
      activeTabInUrl ? getDates(activeTabInUrl).created_before : moment().format('YYYY-MM-DD'),
   );
   const history = useHistory();

   const fetchBusinessDetails = async () => {
      setLoading(true);
      try {
         const res = await getBusinessDetails(business?.business_account);
         setAccountDetails(res?.data);
         setLoading(false);
      } catch (error) {
         toast.error(error?.message || 'something went wrong');
         setLoading(false);
      }
   };

   useEffect(() => {
      if (business?.business_account) fetchBusinessDetails();
   }, [business?.business_account]);

   const initializeBusiness = async () => {
      setLoading(true);
      try {
         let response = '';
         if (filter === 'all') {
            const { results } = await fetchBusinessForApprovals({
               business_status: 'approved',
               business_type: businessType,
               tier: currentTier,
            });
            response = results;
            setBusiness(results[0]);
            setLoading(false);
         } else {
            const { results } = await fetchBusinessForApprovals({
               ...(filter !== 'last-30' && { created_after: startDate }),
               ...(filter !== 'last-30' && { created_before: endDate }),
               business_status: 'approved',
               business_type: businessType,
               tier: currentTier,
            });
            response = results;
            setBusiness(results[0]);
            setLoading(false);
         }
         return response;
      } catch (e) {
         toast.error(e.message);
         setLoading(true);
      }
   };

   const updateDocStatus = async ({ payload, businessDetailId }) => {
      setUpdating(true);
      try {
         const res = await updateBusinessAccountApi(payload, null, businessDetailId);
         toast.success(res?.message || 'Action Successful');
      } catch (e) {
         toast.error(e?.message || 'Something went wrong');
      }
      setUpdating(false);
   };

   const renderActiveTab = () => {
      switch (activeTab) {
         case 'overview':
            return (
               <PendingDocument2
                  business={business}
                  businessType={businessType}
                  accountDetails={accountDetails}
                  currentTier={currentTier}
                  initializeBusiness={initializeBusiness}
                  updateDocStatus={updateDocStatus}
                  updating={updating}
               />
            );

         case 'stats':
            return (
               <div>
                  <ApproverStats />
               </div>
            );

         case 'notes':
            return <Notes businessAccountId={business?.business_account} />;

         default:
            return <PendingDocument2 />;
      }
   };

   useEffect(() => {
      (async function initialize() {
         try {
            window.scroll(0, 0);
            if (activeTab === 'overview') {
               initializeBusiness();
            }
         } catch (e) {
            toast.error(e.message);
         }
      })();
   }, [filter, currentTier, businessType]);

   return (
      <>
         <LoggedInDashboard pageTitle="">
            <div className="d-flex justify-content-between mb-2 px-4 pb-md-5 align-items-center">
               <HeadingOne> Pending Documents</HeadingOne>
               <div>
                  <Button
                     variant={filter === 'all' ? 'primary' : 'secondary'}
                     className="me-2"
                     onClick={() => {
                        setStartDate('2019-01-01');
                        setEndDate(moment().format('YYYY-MM-DD'));
                        setFilter('all');
                        history.push(`${location.pathname}?active=all`);
                     }}
                  >
                     All
                  </Button>

                  <Button
                     variant={filter === 'last-24' ? 'primary' : 'secondary'}
                     onClick={() => {
                        setStartDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
                        setEndDate(moment().format('YYYY-MM-DD'));
                        setFilter('last-24');
                        history.push(`${location.pathname}?active=last-24`);
                     }}
                     className="me-2"
                  >
                     Last 24 hours
                  </Button>

                  <Button
                     variant={filter === 'last-7' ? 'primary' : 'secondary'}
                     onClick={() => {
                        setFilter('last-7');
                        setStartDate(moment().subtract(9, 'days').format('YYYY-MM-DD'));
                        setEndDate(moment().subtract(2, 'days').format('YYYY-MM-DD'));
                        history.push(`${location.pathname}?active=last-7`);
                     }}
                  >
                     Last 7 days
                  </Button>
               </div>
            </div>
            {businessType === 'registered' && (
               <div className="d-flex gap-3 mb-2 px-4 pb-md-5 align-items-center">
                  <Button
                     variant={currentTier === 'tier_3' ? 'primary' : 'secondary'}
                     onClick={() => setCurrentTier('tier_3')}
                  >
                     Tier 3
                  </Button>
                  <Button
                     variant={currentTier === 'tier_4' ? 'primary' : 'secondary'}
                     onClick={() => setCurrentTier('tier_4')}
                  >
                     Tier 4
                  </Button>
               </div>
            )}

            <TabWrapper>
               <ul className="m-0 d-flex align-items-center">
                  <li
                     className={`${
                        activeTab === 'overview' ? 'active' : ''
                     } pending-doc-overview pending-tab-action`}
                     onClick={() => setActiveTab('overview')}
                  >
                     Overview
                  </li>
                  <li
                     className={`${
                        activeTab === 'notes' ? 'active' : ''
                     } pending-doc-overview pending-tab-action`}
                     onClick={() => setActiveTab('notes')}
                  >
                     Notes
                  </li>

                  <li
                     className={`${
                        activeTab === 'stats' ? 'active' : ''
                     } pending-doc-overview pending-tab-action`}
                     onClick={() => setActiveTab('stats')}
                  >
                     Stats
                  </li>
               </ul>
            </TabWrapper>
            {!loading ? renderActiveTab() : <Spinner />}
         </LoggedInDashboard>
      </>
   );
}
ApproverContainer.propTypes = {
   businessType: string.isRequired,
};
