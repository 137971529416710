import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Input } from 'components/input';
import { TableComponent } from 'components/table';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { TableContainer, TableContent } from 'styles/table';
import BusinessDetails from 'containers/business-details';
import { FilterContainer } from 'pages/follow-up/follow-up.style';
import { fetchBusinessAccountsApi } from 'services/business';
import { freelancerAccountsColumn } from './utils';
import { BusinessAccountsFilerModal } from './features/filter-modal';

const initialFilter = {
   created_after: '2019-01-01',
   created_before: moment().format('YYYY-MM-DD'),
   business_type: 'freelancer',
};

export default function FreelancerAccountsDashboard() {
   const { setActiveTab } = useBizDetailsModal();
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page') || 1;
   const [businessAccountsData, setBusinessAccountsData] = useState([]);
   const [loading, setLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(page);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   const [bizDetail, setBizDetail] = useState('');
   const [noCloseIcon, setNoCloseIcon] = useState(false);
   const [filterData, setFilterData] = useState(initialFilter);
   const [filterPayload, setFilterPayload] = useState({});

   const fetchBusinessAccounts = async () => {
      try {
         setLoading(true);
         setBusinessAccountsData([]);
         setPaginationData({});
         const params = {
            page: currentPage,
            ...filterData,
         };
         const result = await fetchBusinessAccountsApi(params);
         setBusinessAccountsData(result.results || []);
         setPaginationData(result);
      } catch (err) {
         return err;
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchBusinessAccounts();
   }, [currentPage, filterPayload]);

   function handleOpenModal() {
      setActiveTab('overview');
      setModalVisibility(true);
      setNoCloseIcon(false);
   }

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   function onRowClick({ row }) {
      setBizAccount(row.business_account);
      setBizDetail(row.id);
   }

   return (
      <LoggedInDashboard>
         <TableContainer id="table">
            <TableContent>
               {loading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> Freelancer Accounts</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex justify-content-between">
                  <div className="me-3">
                     <Input style={{ width: '300px' }} placeholder="Search" />
                  </div>

                  <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                     Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                  </FilterContainer>
               </div>
            </TableContent>
            <TableComponent
               columns={freelancerAccountsColumn(handleOpenModal)}
               rows={businessAccountsData}
               isLoading={loading}
               onPageChange={onPageChange}
               count={Math.round(paginationData.count / 15)}
               page={currentPage}
               pageSize={20}
               onRowClick={onRowClick}
            />
         </TableContainer>
         {modalVisibility && (
            <BusinessDetails
               businessDetailId={bizDetail}
               businessAccountId={bizAccount}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
               noCloseIcon={noCloseIcon}
            />
         )}
         <BusinessAccountsFilerModal
            open={modalVisibilityFilter}
            onClose={() => setModalVisibilityFilter(false)}
            setCurrentPage={setCurrentPage}
            filterData={filterData}
            setFilterData={setFilterData}
            setFilterPayload={setFilterPayload}
         />
      </LoggedInDashboard>
   );
}
