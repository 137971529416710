import { useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes, { bool, func, number } from 'prop-types';
import { confirm } from 'components/confirm-box';
import useFetchManagers from 'hooks/fetchManagers';
import Modal from 'components/modal';
import { SelectInput } from 'components/select';
import { Button } from 'components/button';
import { InputWrapper } from 'styles/form';
import { bulkAssignTickets } from 'services/ticketing';

export default function BulkAssignTicketsComponent({ open, onClose, ticketIds, fetchAllTickets }) {
   const { managers } = useFetchManagers();
   const [manager, setManager] = useState({});
   const [loading, setLoading] = useState(false);

   const handleSubmit = async () => {
      setLoading(true);
      try {
         const payload = {
            ticket_ids: ticketIds,
            assigned_to: manager?.value,
            issue_status: 'open',
         };
         const res = await bulkAssignTickets(payload);
         fetchAllTickets();
         setLoading(false);
         onClose();
         toast.success(res?.message || 'Action Successful ');
      } catch (error) {
         toast.error(error?.message || 'Something went wrong');
         setLoading(false);
      }
   };

   const confirmSubmit = () => {
      confirm({
         confirmText: 'Do you want to proceed with this action?',
         onConfirm: handleSubmit,
      });
   };

   return (
      <Modal open={open} onClose={onClose} title="Bulk Assign Tickets" center>
         <InputWrapper className="grid-2-2">
            <SelectInput
               onChange={(val) => setManager(val)}
               label="Manager"
               options={managers}
               value={managers.find((item) => item.value === manager?.value)}
            />
         </InputWrapper>

         <div className="d-flex justify-content-end">
            <Button type="button" isLoading={loading} onClick={confirmSubmit}>
               Assign Tickets
            </Button>
         </div>
      </Modal>
   );
}
BulkAssignTicketsComponent.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   ticketIds: PropTypes.arrayOf(number).isRequired,
   fetchAllTickets: func.isRequired,
};
