export const getStatus = (status) => {
   switch (status) {
      case 'confirmed':
      case 'confirmed_manual':
         return 'verified';

      case true:
         return 'signed';

      case 'failed_auto_verify':
         return 'failed';

      case 'pending':
      case 'under_review':
      case false:
         return 'pending';

      case 'rejected':
         return 'rejected';

      default:
         return null;
   }
};

export const businessAccountDocumentTypes = (bizDetails) => [
   {
      text: 'Bank Verification Number',
      key: getStatus(bizDetails?.owner_details?.bvn_status),
   },
   {
      text: 'National Identification Number',
      key: getStatus(bizDetails?.owner_details?.nin_status),
   },

   {
      text: 'CAC',
      key: getStatus(bizDetails?.cac_status),
   },
   {
      text: 'TIN',
      key: getStatus(bizDetails?.tin_status),
   },
   {
      text: 'Indemnity',
      key: getStatus(bizDetails?.indemnity_signed),
   },
];

export const freelancerAccountDocumentTypes = (bizDetails) => [
   {
      text: 'Bank Verification Number',
      key: getStatus(bizDetails?.owner_details?.bvn_status),
   },
   {
      text: 'National Identification Number',
      key: getStatus(bizDetails?.owner_details?.nin_status),
   },
   {
      text: 'Indemnity',
      key: getStatus(bizDetails?.indemnity_signed),
   },
];
