import React from 'react';
import ApproverContainer from '../../index';

export default function RegisteredApprover() {
   return (
      <div>
         <ApproverContainer businessType="registered" />
      </div>
   );
}
