import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { func, bool } from 'prop-types';
import { Input } from 'components/input';
import Modal from 'components/modal';
import { InputWrapper } from 'styles/form';
import useFetchManagers from 'hooks/fetchManagers';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';

const activity_type = [
   {
      value: 'manual_bvn_verification',
      label: 'Manual BVN Verification',
   },
   {
      value: 'pin_reset',
      label: 'Pin Reset',
   },
   {
      value: 'refund',
      label: 'Refund',
   },
   {
      value: 'manual_debit',
      label: 'Manual Debit',
   },
   {
      value: 'update_biz_name',
      label: 'Update Business Name',
   },
   {
      value: 'extract_utility_token',
      label: 'Extract Utility Token',
   },
   {
      value: 'account_upgrade',
      label: 'Account Upgrade',
   },
];

const LogFilterModal = ({ open, onClose, filterData, setFilterData, setFilterPayload }) => {
   const [isLoading, setIsLoading] = useState(false);
   const { managers } = useFetchManagers();
   const [isClearingFilter, setIsClearingFilter] = useState(false);
   const history = useHistory();

   useEffect(() => {
      return function unMount() {
         setIsLoading(false);
      };
   }, []);

   function onChange(name, data) {
      setFilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      setIsLoading(true);
      try {
         history.push(`${history.location.pathname}?page=1`);
         setFilterPayload(filterData);
         onClose();
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setIsLoading(false);
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   async function handleClearFilter() {
      setIsClearingFilter(true);
      try {
         history.push(`${history.location.pathname}?page=1`);
         setFilterData({});
         setFilterPayload({});
         onClose();
      } catch (err) {
         return err;
      }
      setIsClearingFilter(false);
   }

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={filterData?.start_date}
                  onChange={({ target }) => onChange('start_date', target)}
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  value={filterData?.end_date}
                  onChange={({ target }) => onChange('end_date', target)}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  label="Activity Type"
                  options={activity_type}
                  value={activity_type.find((item) => item.value === filterData?.activity_type)}
                  onChange={(target) => onChange('activity_type', target)}
               />

               <SelectInput
                  label="Manager"
                  options={managers}
                  value={managers.find((item) => item.value === filterData?.manager_id)}
                  onChange={(target) => onChange('manager_id', target)}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={handleClearFilter}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export default LogFilterModal;

LogFilterModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   filterData: func.isRequired,
   setFilterData: func.isRequired,
   setFilterPayload: func.isRequired,
};
