import { useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { NumberInput } from 'components/input';
import { updateAccountLimitsApi } from 'services/business';

export default function FreelancerAccountLimitSettings({
   freelanceResponseData,
   freelancerAccountLimits,
   setFreelancerAccountLimits,
   fetchFreelancerLimits,
}) {
   const [updatingData, setUpdatingData] = useState(false);

   const TIER1 = 'tier_1';
   const TIER2 = 'tier_2';
   const TIER3 = 'tier_3';

   const mapTierToName = {
      [TIER1]: 'Basic',
      [TIER2]: 'Standard',
      [TIER3]: 'Professional',
   };

   const onUpdate = async (name) => {
      setUpdatingData(true);
      try {
         const value = freelancerAccountLimits[name];
         const { daily_limit, single_limit, id } = value;

         const res = await updateAccountLimitsApi(id, { daily_limit, single_limit });
         toast.success(res?.message || 'Operation successful');
         fetchFreelancerLimits();
         setUpdatingData(false);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong');
         setUpdatingData(false);
      }
   };

   const confirmUpdate = (name) => {
      confirm({
         confirmText: 'Are you sure want to update the settings?',
         onConfirm: () => onUpdate(name),
      });
   };

   const onChange = (name, type, val) => {
      setFreelancerAccountLimits({
         ...freelancerAccountLimits,
         [name]: {
            ...freelancerAccountLimits[name],
            [type]: val?.value,
         },
      });
   };

   const isValid = (name, type) => {
      const existing = freelanceResponseData.find((item) => item.name === name);
      if (!existing) return false;
      const oldValue = existing[type];
      const newValue = freelancerAccountLimits[name] ? freelancerAccountLimits[name][type] : null;
      return oldValue !== newValue && newValue;
   };

   return (
      <>
         <h2 className="border-bottom pb-4 ms-1 pt-3">Freelancer Account</h2>
         <div>
            {Object.keys(mapTierToName).map((tier, index) => (
               <div key={tier}>
                  <h3 className="mt-3 mb-5">{mapTierToName[tier]}</h3>
                  <div className="d-flex gap-5">
                     {/* SINGLE TRANSACTIONS LIMIT */}

                     <div
                        className={classNames('col-lg-4 mb-5', {
                           'pe-5': (index + 1) % 3 !== 0,
                        })}
                     >
                        <h4 className="mb-3">Single Transaction Limit</h4>
                        <NumberInput
                           label={`This is the single transaction limit for a ${mapTierToName[tier]} Freelancer account`}
                           className="wrap-label-input"
                           value={freelancerAccountLimits[tier]?.single_limit || ''}
                           onChange={(valuesObject) => {
                              onChange(tier, 'single_limit', {
                                 value: valuesObject.floatValue,
                              });
                           }}
                        />
                        <Button
                           className="w-100 mt-3"
                           onClick={() => confirmUpdate(tier)}
                           disabled={!isValid(tier, 'single_limit') || updatingData}
                        >
                           Update
                        </Button>
                     </div>

                     {/* DAILY TRANSACTIONS LIMIT */}

                     <div
                        className={classNames('col-lg-4 mb-5', {
                           'pe-5': (index + 1) % 3 !== 0,
                        })}
                     >
                        <h4 className="mb-3">Daily Transaction Limit</h4>
                        <NumberInput
                           label={`This is the daily transaction limit for a ${mapTierToName[tier]} Freelancer account`}
                           className="wrap-label-input"
                           value={freelancerAccountLimits[tier]?.daily_limit || ''}
                           onChange={(valuesObject) => {
                              onChange(tier, 'daily_limit', {
                                 value: valuesObject.floatValue,
                              });
                           }}
                        />
                        <Button
                           className="w-100 mt-3"
                           onClick={() => confirmUpdate(tier)}
                           disabled={!isValid(tier, 'daily_limit') || updatingData}
                        >
                           Update
                        </Button>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </>
   );
}
FreelancerAccountLimitSettings.propTypes = {
   freelanceResponseData: PropTypes.arrayOf().isRequired,
   freelancerAccountLimits: PropTypes.objectOf().isRequired,
   setFreelancerAccountLimits: PropTypes.func.isRequired,
   fetchFreelancerLimits: PropTypes.func.isRequired,
};
