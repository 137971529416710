import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Spinner } from 'components/spinner';
import { fetchAccountTierLimitsApi } from 'services/business';
import BusinessAccountLimitSettings from './features/business-limits';
import FreelancerAccountLimitSettings from './features/freelancer-limits';

export default function AccountLimitSettings() {
   const [loading, setLoading] = useState(false);

   const [freelanceResponseData, setFreelanceResponseData] = useState([]);
   const [freelancerAccountLimits, setFreelancerAccountLimits] = useState({});

   const [registeredResponseData, setRegisteredResponseData] = useState([]);
   const [registeredAccountLimits, setRegisteredAccountLimits] = useState({});

   const fetchFreelancerLimits = async () => {
      setLoading(true);
      try {
         const response = await fetchAccountTierLimitsApi({ business_type: 'freelancer' });

         let stateValue = {};
         response?.results.forEach(({ name, daily_limit, single_limit, id }) => {
            stateValue = {
               ...stateValue,
               [name]: {
                  id,
                  daily_limit,
                  single_limit,
               },
            };
         });

         setFreelanceResponseData(response?.results);
         setFreelancerAccountLimits(stateValue);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong!');
      } finally {
         setLoading(false);
      }
   };

   const fetchRegisteredLimits = async () => {
      setLoading(true);
      try {
         const response = await fetchAccountTierLimitsApi({ business_type: 'registered' });

         let stateValue = {};
         response?.results.forEach(({ name, daily_limit, single_limit, id }) => {
            stateValue = {
               ...stateValue,
               [name]: {
                  id,
                  daily_limit,
                  single_limit,
               },
            };
         });

         setRegisteredResponseData(response?.results);
         setRegisteredAccountLimits(stateValue);
      } catch (e) {
         toast.error(e?.message || 'Something went wrong!');
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchFreelancerLimits();
      fetchRegisteredLimits();
   }, []);

   return (
      <>
         {loading ? (
            <Spinner />
         ) : (
            <>
               <FreelancerAccountLimitSettings
                  freelanceResponseData={freelanceResponseData}
                  freelancerAccountLimits={freelancerAccountLimits}
                  setFreelancerAccountLimits={setFreelancerAccountLimits}
                  fetchFreelancerLimits={fetchFreelancerLimits}
               />
               <BusinessAccountLimitSettings
                  registeredResponseData={registeredResponseData}
                  registeredAccountLimits={registeredAccountLimits}
                  setRegisteredAccountLimits={setRegisteredAccountLimits}
                  fetchRegisteredLimits={fetchRegisteredLimits}
               />
            </>
         )}
      </>
   );
}
