import moment from 'moment';
import { TooltipComponent } from 'components/tooltip';
import { BusinessAccountStatus } from 'styles/utils';

const mapAccountTier = (tier) => {
   switch (tier) {
      case 'tier_1':
         return 'Basic';
      case 'tier_2':
         return 'Standard';
      case 'tier_3':
         return 'Professional';
      case 'tier_4':
         return 'Prime';

      default:
         return null;
   }
};

const getStatus = (status) => {
   switch (status) {
      case 'confirmed':
      case 'confirmed_manual':
         return 'verified';

      case true:
         return 'Signed';

      case 'failed_auto_verify':
         return 'failed';

      case 'pending':
      case 'under_review':
      case false:
         return 'pending';

      case 'rejected':
         return 'rejected';

      default:
         return null;
   }
};

export const freelancerAccountsColumn = (handleOpenModal) => [
   {
      field: 'business_name',
      headerName: 'Business Name',
      renderCell: ({ row }) => (
         <TooltipComponent title={row.business_name}>
            <span
               style={{
                  cursor: 'pointer',
               }}
               className="text-primary"
               onClick={() => handleOpenModal(row.business_account)}
            >
               {row.business_name || 'N/A'}
            </span>
         </TooltipComponent>
      ),
   },
   {
      field: 'account_tier',
      headerName: 'Account Tier',
      width: 200,
      renderCell: ({ row }) => mapAccountTier(row?.account_tier) || 'N/A',
   },
   {
      field: 'bvn_status',
      headerName: 'BVN',
      renderCell: ({ row }) => (
         <BusinessAccountStatus status={getStatus(row?.owner_details?.bvn_status)}>
            {getStatus(row?.owner_details?.bvn_status) || 'N/A'}
         </BusinessAccountStatus>
      ),
   },
   {
      field: 'nin_status',
      headerName: 'NIN',
      renderCell: ({ row }) => (
         <BusinessAccountStatus status={getStatus(row?.owner_details?.nin_status)}>
            {getStatus(row?.owner_details?.nin_status) || 'N/A'}
         </BusinessAccountStatus>
      ),
   },
   {
      field: 'selfie_status',
      headerName: 'Selfie',
      renderCell: ({ row }) => (
         <BusinessAccountStatus status={getStatus(row?.owner_details?.selfie_status)}>
            {getStatus(row?.owner_details?.selfie_status) || 'N/A'}
         </BusinessAccountStatus>
      ),
   },
   {
      field: 'proof_of_address_status',
      headerName: 'POA',
      width: 150,
      renderCell: ({ row }) => (
         <BusinessAccountStatus status={getStatus(row?.proof_of_address_status)}>
            {getStatus(row?.proof_of_address_status) || 'N/A'}
         </BusinessAccountStatus>
      ),
   },
   {
      field: 'indemnity_signed',
      headerName: 'Indemnity',
      renderCell: ({ row }) => (
         <BusinessAccountStatus status={getStatus(row?.indemnity_signed ? 'confirmed' : 'pending')}>
            {row.indemnity_signed ? 'Accepted' : 'Pending' || 'N/A'}
         </BusinessAccountStatus>
      ),
   },
   {
      field: 'date',
      headerName: 'Date',
      renderCell: ({ row }) => (
         <span>
            {moment(row?.created).format('MMM DD, YYYY')} | {moment(row?.created).format('h:mm a')}
         </span>
      ),
   },
];
