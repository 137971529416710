import PropTypes, { bool, func, string } from 'prop-types';
import Modal from 'components/modal';
import { Button } from 'components/button';
import DocumentViewer from 'components/document-previewer';

import { ReferenceDetailsWrapper } from '../new-documents.style';

export default function ReferenceInfoModal({
   open,
   onClose,
   details,
   referenceNo,
   onSubmit,
   loading,
}) {
   const { referee_details } = details || {};
   return (
      <Modal open={open} onClose={onClose} title={`Reference ${referenceNo}`} center width="750px">
         <ReferenceDetailsWrapper>
            <div className="row pt-5 border-bottom">
               <div className="col-lg-4 col-md-6 mb-3 mb-3">
                  <div className="business-details-item">
                     <h3>Full Name</h3>
                     <p>
                        {details?.first_name || 'N/A'} {details?.last_name || 'N/A'}
                     </p>
                  </div>
               </div>
               <div className="col-lg-4 col-md-6 mb-3 ">
                  <div className="business-details-item">
                     <h3>Phone Number</h3>
                     <p>{referee_details?.phone || 'N/A'}</p>
                  </div>
               </div>
               <div className="col-lg-4 col-md-6 mb-3 ">
                  <div className="business-details-item">
                     <h3>Email</h3>
                     <p>{details?.email || 'N/A'}</p>
                  </div>
               </div>
               <div className="col-lg-4 col-md-6 mb-3 ">
                  <div className="business-details-item">
                     <h3>Relationship with member</h3>
                     <p>{referee_details?.relationship || 'N/A'}</p>
                  </div>
               </div>
               <div className="col-lg-4 col-md-6 mb-3 ">
                  <div className="business-details-item">
                     <h3>How long have they known the member</h3>
                     <p>{referee_details?.years_known || 'N/A'} Years</p>
                  </div>
               </div>
               <div className="col-lg-4 col-md-6 mb-3 ">
                  <div className="business-details-item">
                     <h3>Business Name</h3>
                     <p>{referee_details?.business_name || 'N/A'}</p>
                  </div>
               </div>
               <div className="col-lg-4 col-md-6 mb-3 ">
                  <div className="business-details-item">
                     <h3>Business Registration number</h3>
                     <p>{referee_details?.cac || 'N/A'}</p>
                  </div>
               </div>
               <div className="col-lg-4 col-md-6 mb-3 ">
                  <div className="business-details-item">
                     <h3>Address</h3>
                     <p>
                        {`${referee_details.address_details?.house_no}, ${referee_details.address_details?.address_line}, ${referee_details.address_details?.city}, ${referee_details.address_details?.state}` ||
                           'N/A'}
                     </p>
                  </div>
               </div>
            </div>
            <div className="footer">
               <div className="business-details-item">
                  <h2>NIN</h2>
                  <DocumentViewer url={referee_details.nin_document} title="NIN">
                     <Button>Preview Document</Button>
                  </DocumentViewer>
               </div>
               <div className="business-details-item">
                  <h2>Signature</h2>
                  <DocumentViewer url={referee_details.signature_document} title="Signature">
                     <Button>Preview Document</Button>
                  </DocumentViewer>
               </div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
               <Button
                  variant="danger"
                  className="me-3"
                  onClick={() => onSubmit(details?.id, 'rejected')}
                  disabled={loading}
               >
                  Reject
               </Button>

               <Button onClick={() => onSubmit(details?.id, 'confirmed')} disabled={loading}>
                  Approve
               </Button>
            </div>
         </ReferenceDetailsWrapper>
      </Modal>
   );
}
ReferenceInfoModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   details: PropTypes.objectOf(string).isRequired,
   referenceNo: string.isRequired,
   onSubmit: func.isRequired,
   loading: bool.isRequired,
};
