import { useState, useEffect } from 'react';
import PropTypes, { func, number, objectOf, string } from 'prop-types';
import { Input } from 'components/input';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { Button } from 'components/button';
import { updateBizDetails } from 'services/businessDetails';
import { getPCRLabel } from 'utils/loans';
import { Status } from 'styles/utils';
import { numberWithCommas } from 'utils/others';
import { updateBusinessAccountApi } from 'services/business';
import PlanField from './plan-field';
import {
   InfoGroup,
   Label,
   OverviewSection,
   SectionTitle,
   Value,
   BoolStatus,
} from '../utils/overview.style';

function BusinessInfo({
   bizDetails,
   businessAccountId,
   businessDetailId,
   fetchBusinessDetails,
   statusValue,
   bizPcr,
   changePlanFocus,
   plans,
}) {
   const [updatingBiz, setUpdatingBiz] = useState(false);
   const [bizName, setBizName] = useState('');
   const [bizFriendlyName, setBizFriendlyName] = useState('');
   const [bizEmail, setBizEmail] = useState('');
   const [bizDescription, setBizDescription] = useState('');
   const [tinNumber, setTinNumber] = useState('');
   const [bizAddress, setBizAddress] = useState('');
   const [bizCity, setBizCity] = useState('');
   const [bizState, setBizState] = useState('');
   const [rcNumber, setRcNumber] = useState('');
   const dateCreated = moment(bizDetails?.pub_date).format('MMM DD, YYYY');
   const timeCreated = moment(bizDetails?.pub_date).format('h:mm a');

   const bizUnchanged = () =>
      bizName === bizDetails?.biz_name &&
      bizFriendlyName === bizDetails?.biz_friendly_name &&
      bizEmail === bizDetails?.biz_email &&
      tinNumber === bizDetails?.tin_number &&
      bizDescription === bizDetails?.biz_description &&
      bizAddress === bizDetails?.biz_address &&
      bizCity === bizDetails?.biz_city &&
      bizState === bizDetails?.biz_state &&
      rcNumber === bizDetails?.rc_number;

   useEffect(() => {
      setBizName(bizDetails?.biz_name);
      setBizFriendlyName(bizDetails?.biz_friendly_name);
      setBizEmail(bizDetails?.biz_email);
      setTinNumber(bizDetails?.tin_number);
      setBizDescription(bizDetails?.biz_description);
      setBizAddress(bizDetails?.biz_address);
      setBizCity(bizDetails?.biz_city);
      setBizState(bizDetails?.biz_state);
      setRcNumber(bizDetails?.rc_number);
   }, [bizDetails]);

   const editBizDetails = async (e) => {
      e.preventDefault();
      setUpdatingBiz(true);

      const bizData = {
         biz_account_id: businessAccountId,
         ...(bizName !== bizDetails?.biz_name && { biz_name: bizName }),
         ...(bizFriendlyName !== bizDetails?.biz_friendly_name && {
            biz_friendly_name: bizFriendlyName,
         }),
         ...(bizEmail !== bizDetails?.biz_email && { biz_email: bizEmail }),
         ...(bizDescription !== bizDetails?.biz_description && { biz_description: bizDescription }),
         ...(bizAddress !== bizDetails?.biz_address && { biz_address: bizAddress }),
         ...(bizCity !== bizDetails?.biz_city && { biz_city: bizCity }),
         ...(bizState !== bizDetails?.biz_state && { biz_state: bizState }),
         ...(rcNumber !== bizDetails?.rc_number && { biz_rc_number: rcNumber }),
      };

      try {
         const editBizResult = await updateBizDetails(bizData);

         if (editBizResult.ok) {
            fetchBusinessDetails();
            toast.success(editBizResult?.message, { duration: 3000 });
         }
         setUpdatingBiz(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setUpdatingBiz(false);
      }
   };

   const handleUpdateTin = async () => {
      setUpdatingBiz(true);
      try {
         const payload = {
            tin: tinNumber,
         };
         const res = await updateBusinessAccountApi(payload, '', businessDetailId);
         toast.success(res?.message || 'Successful');
         setUpdatingBiz(false);
         fetchBusinessDetails();
      } catch (error) {
         toast.error(error?.message || 'Something went wrong');
         setUpdatingBiz(false);
      }
   };

   return (
      <>
         <form onSubmit={editBizDetails}>
            <SectionTitle>Business Details</SectionTitle>
            <OverviewSection>
               <InfoGroup>
                  <Label>Business Name</Label>
                  <Input
                     className="overview-input"
                     id="bizName"
                     value={bizName}
                     onChange={(e) => setBizName(e.target?.value)}
                  />
               </InfoGroup>
               <InfoGroup>
                  <Label>Business Friendly Name</Label>
                  <Input
                     className="overview-input"
                     id="bizFriendlyName"
                     value={bizFriendlyName}
                     onChange={(e) => setBizFriendlyName(e.target?.value)}
                  />
               </InfoGroup>
               <InfoGroup>
                  <Label>Business Email</Label>
                  <Input
                     className="overview-input"
                     id="bizEmail"
                     value={bizEmail}
                     onChange={(e) => setBizEmail(e.target?.value)}
                  />
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Phone</Label>
                  <Value>{bizDetails?.biz_phone || 'N/A'}</Value>
               </InfoGroup>
               <InfoGroup>
                  <Label>Business Type</Label>
                  <Value>{bizDetails?.biz_type}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Description</Label>
                  <Input
                     className="overview-input"
                     id="bizDescription"
                     value={bizDescription}
                     onChange={(e) => setBizDescription(e.target?.value)}
                  />
               </InfoGroup>

               <InfoGroup>
                  <Label>Prospa Credit Score</Label>
                  <Value>
                     {getPCRLabel(bizPcr)} | {bizPcr || 'N/A'}
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>CAC Certificate Verified</Label>
                  <Status status={bizDetails?.cac_cert_photo_status}>
                     {statusValue(bizDetails?.cac_cert_photo_status)}
                  </Status>
               </InfoGroup>

               <InfoGroup>
                  <Label>Proof of Address</Label>
                  <Status status={bizDetails?.proof_of_address_status}>
                     {statusValue(bizDetails?.proof_of_address_status)}
                  </Status>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Selected From CAC</Label>
                  <BoolStatus isTrue={bizDetails?.cac_biz_tag !== 0}>
                     {bizDetails?.cac_biz_tag === 0 ? 'No' : 'Yes'}
                  </BoolStatus>
               </InfoGroup>

               {changePlanFocus && (
                  <PlanField
                     bizDetails={bizDetails}
                     fetchBusinessDetails={fetchBusinessDetails}
                     changePlanFocus={changePlanFocus}
                     plans={plans}
                  />
               )}

               <InfoGroup>
                  <Label>Tax Identification Number</Label>
                  <Input
                     id="tinNumber"
                     placeholder="TIN"
                     className="overview-input"
                     value={tinNumber}
                     onChange={(e) => setTinNumber(e.target?.value)}
                  />
                  <Button
                     style={{
                        marginTop: '5px',
                        minHeight: '25px',
                        padding: '7px',
                        fontSize: '12px',
                        background: '#FA4A84',
                     }}
                     onClick={handleUpdateTin}
                  >
                     Update
                  </Button>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Address</Label>
                  <Input
                     id="bizAddress"
                     placeholder="Address"
                     className="overview-input"
                     value={bizAddress}
                     onChange={(e) => setBizAddress(e.target?.value)}
                  />
                  <Input
                     id="bizCity"
                     placeholder="City"
                     className="overview-input"
                     value={bizCity}
                     onChange={(e) => setBizCity(e.target?.value)}
                  />
                  <Input
                     id="bizState"
                     placeholder="State"
                     className="overview-input"
                     value={bizState}
                     onChange={(e) => setBizState(e.target?.value)}
                  />
               </InfoGroup>

               <InfoGroup>
                  <Label>Registered Business Address</Label>
                  <Value>{bizDetails?.reg_biz_address || 'N/A'}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Registration Number</Label>
                  <Input
                     id="rcNumber"
                     placeholder="Reg. Number"
                     className="overview-input"
                     value={rcNumber}
                     onChange={(e) => setRcNumber(e.target?.value)}
                  />
               </InfoGroup>

               <InfoGroup>
                  <Label>Turnover</Label>
                  <Value>
                     {bizDetails?.biz_turnover
                        ? bizDetails?.biz_turnover?.replace(/_/g, ' ')
                        : 'N/A'}
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Manager</Label>
                  <Value>
                     {bizDetails?.assigned_manager_details
                        ? `${bizDetails?.assigned_manager_details?.first_name} ${bizDetails?.assigned_manager_details?.last_name}`
                        : 'N/A'}
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Build Partner</Label>
                  <Value>
                     {bizDetails?.build_manager_details
                        ? `${bizDetails?.build_manager_details.first_name} ${bizDetails?.build_manager_details.last_name}`
                        : 'N/A'}
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Account ID</Label>
                  <Value>{bizDetails.biz_account_id}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Date Created</Label>
                  <Value style={{ textTransform: 'initial' }}>
                     {dateCreated} | {timeCreated}
                  </Value>
               </InfoGroup>
               <InfoGroup>
                  <Label>Deposit Value</Label>
                  <Value>₦ {numberWithCommas(bizDetails?.deposit_value)}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Products</Label>
                  <Value>{bizDetails?.total_products}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Customers</Label>
                  <Value>{bizDetails?.total_customers}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Employees</Label>
                  <Value>{bizDetails?.total_employees}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Invoices</Label>
                  <Value>{bizDetails?.total_invoices}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Suppliers</Label>
                  <Value>{bizDetails?.total_suppliers}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Virtual Cards</Label>
                  <Value>{bizDetails?.total_virtual_cards}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Webstore URL</Label>
                  <Value>
                     {bizDetails?.webstore_details?.has_webstore ? (
                        <a
                           target="_blank"
                           rel="noreferrer"
                           className="number"
                           href={`${
                              process.env.REACT_APP_WEBSTORE_BASEURL ||
                              'https://getprospa.com/webstore/'
                           }${bizDetails?.webstore_details?.webstore_url}`}
                        >{`${
                           process.env.REACT_APP_WEBSTORE_BASEURL ||
                           'https://getprospa.com/webstore/'
                        }${bizDetails?.webstore_details?.webstore_url}`}</a>
                     ) : (
                        <Status status="rejected" className="ms-0">
                           No webstore
                        </Status>
                     )}
                  </Value>
               </InfoGroup>
            </OverviewSection>

            <div className="d-flex justify-content-end mt-2">
               <Button
                  type="submit"
                  disabled={bizUnchanged() || updatingBiz}
                  isLoading={updatingBiz}
               >
                  Update Business Details
               </Button>
            </div>
         </form>
      </>
   );
}

BusinessInfo.propTypes = {
   bizDetails: objectOf(string).isRequired,
   businessAccountId: number.isRequired,
   businessDetailId: number.isRequired,
   fetchBusinessDetails: func.isRequired,
   statusValue: func.isRequired,
   bizPcr: string.isRequired,
   changePlanFocus: func.isRequired,
   plans: PropTypes.objectOf().isRequired,
};

export default BusinessInfo;
