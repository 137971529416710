import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'hooks/auth';
import { ArrowRightIcon } from 'styles/utils';
import { NavItemWrapper } from './style';

function NavItem({ link, text, icon, subMenu, subMenuActive }) {
   const { logout } = useAuth();

   return (
      <NavItemWrapper onClick={link === 'sign-out' ? logout : () => {}}>
         {link ? (
            <NavLink
               to={link === 'sign-out' ? '/#' : link}
               className="nav-link d-flex align-items-center"
               exact
            >
               <div className="d-flex  w-100">
                  <span data-feather={icon} />
                  {text}
               </div>
            </NavLink>
         ) : (
            <p className="nav-link d-flex align-items-center">
               <div className="d-flex w-100">
                  <span data-feather={icon} />
                  {text}
               </div>
               {subMenu && (
                  <ArrowRightIcon
                     height={15}
                     width={15}
                     className={`arrow-icon ${subMenuActive === text ? 'active' : ''}`}
                  />
               )}
            </p>
         )}
      </NavItemWrapper>
   );
}
NavItem.propTypes = {
   link: PropTypes.string.isRequired,
   text: PropTypes.string.isRequired,
   icon: PropTypes.string.isRequired,
   subMenu: PropTypes.bool.isRequired,
   subMenuActive: PropTypes.string.isRequired,
};

export default NavItem;
