import styled from 'styled-components/macro';

export const DocumentsWrapper = styled.div`
   .approve-btn {
      background-color: #198754;
   }

   .director-icon {
      margin-right: 0.3rem;
      font-size: 20px;
   }

   .doc-btn {
      padding: 1.2rem;
      min-height: initial;
      margin-top: 1rem;
   }

   .upload-btn {
      @media (max-width: 600px) {
         display: block !important;
         display: none;
      }
   }

   .file-input {
      box-shadow: none;
      &::-webkit-file-upload-button {
         min-height: 50px;
      }

      @media (max-width: 600px) {
         display: block !important;
      }
   }
`;

export const DocumentSection = styled.div`
   .doc-label {
      margin-right: 1rem;
      font-weight: 600;
   }

   border-bottom: 1px solid #d7dce0;
   padding-bottom: 2rem;
   margin-bottom: 2rem;
`;

export const DocumentHeading = styled.div`
   display: flex;
   align-items: center;
`;

export const DocumentControls = styled.div`
   display: flex;
   align-items: center;

   @media (max-width: 365px) {
      display: initial;
   }
`;

export const ReferenceDetailsWrapper = styled.div`
   .business-details-item h3 {
      font-size: 15px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.4000000059604645px;
      text-align: left;
      color: #000000;
      margin-bottom: 10px;
   }

   .business-details-item h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 25.14px;
      text-align: left;
      color: #222529;
      margin-bottom: 10px;
   }
   .business-details-item {
      margin-bottom: 32px;
   }
   .business-details-item p {
      color: #3a434b;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4000000059604645px;
      text-align: left;
   }
   .footer {
      margin-top: 20px;
      margin-bottom: 50px;
      display: flex;
      justify-content: flex-start;
      gap: 35px;
   }
`;
