import { useEffect, useState } from 'react';
import { number } from 'prop-types';
import toast from 'react-hot-toast';
import { Button } from 'components/button';
import { Spinner } from 'components/spinner';
import DocumentPreviewer from 'components/document-previewer';
import { AlertMessage } from 'components/message';
import { confirm } from 'components/confirm-box';
import { BusinessAccountStatus } from 'styles/utils';
import { fetchSingleBusinessAccountApi, updateBusinessAccountApi } from 'services/business';
import { triggerTinMail } from 'services/businessDetails';
import {
   DocumentControls,
   DocumentHeading,
   DocumentSection,
   DocumentsWrapper,
} from './new-documents.style';
import { businessAccountDocumentTypes, freelancerAccountDocumentTypes, getStatus } from './utils';
import ReferencesContainer from './features/references';

const NewDocuments = ({ businessAccountId, businessDetailId }) => {
   const [bizDetails, setBizDetails] = useState({});
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState('');
   const [uploadingDoc, setUploadingDoc] = useState(null);
   const [proofOfAddress, setProofOfAddress] = useState(null);
   const [boardResolution, setBoardResolution] = useState(null);
   const [statusReport, setStatusReport] = useState(null);
   const [tinDoc1, setTinDoc1] = useState(null);
   const [tinDoc2, setTinDoc2] = useState(null);
   const [tinDoc3, setTinDoc3] = useState(null);
   const [tinDoc4, setTinDoc4] = useState(null);
   const [isVerifying, setIsVerifying] = useState(false);

   const confirmed = 'confirmed';
   const rejected = 'rejected';

   const fetchBusinessDetails = async () => {
      try {
         const result = await fetchSingleBusinessAccountApi(businessDetailId);
         if (result.ok) {
            setBizDetails(result);
            setError('');
         }
         setLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setLoading(false);
      }
   };

   const verifyDoc = async (docKey, verificationAction) => {
      setIsVerifying(true);
      const verifyData = {
         [docKey]: verificationAction,
      };

      try {
         const result = await updateBusinessAccountApi(verifyData, docKey, businessDetailId);
         if (result.ok) {
            toast.success('Operation successful');
            setError('');
         }
         fetchBusinessDetails();
         setLoading(false);
         setIsVerifying(false);
      } catch (err) {
         toast.error(err.message || err.custom_message, { duration: 3000 });
         setError(err.message || err.custom_message);
         setLoading(false);
         setIsVerifying(false);
      }
   };

   const uploadBizDoc = async (docKey) => {
      setUploadingDoc(docKey);
      const file = (key) => {
         if (key === 'proof_of_address_doc') return proofOfAddress;
         if (key === 'board_resolution_doc') return boardResolution;
         if (key === 'status_report_doc') return statusReport;
         if (key === 'tin1') return tinDoc1;
         if (key === 'tin2') return tinDoc2;
         if (key === 'tin3') return tinDoc3;
         if (key === 'tin4') return tinDoc4;
         return null;
      };
      try {
         const formData = new FormData();
         formData.append(docKey, file(docKey));

         const result = await updateBusinessAccountApi(formData, docKey, businessDetailId);
         if (result) {
            toast.success(result?.data?.message || 'Successful');
            setError('');
            setBoardResolution(null);
            setStatusReport(null);
            setProofOfAddress(null);
            setTinDoc1(null);
            setTinDoc2(null);
            setTinDoc3(null);
            setTinDoc4(null);

            setUploadingDoc(null);
         }
         fetchBusinessDetails();
      } catch (err) {
         toast.error(err.response?.data?.message || err?.message, { duration: 3000 });
         setError(err.response?.data?.message || err?.message);

         setUploadingDoc(null);
      }
   };

   const handleVerify = (key, value) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isVerifying,
         onConfirm: () => verifyDoc(key, value),
      });
   };

   const processTIN = async () => {
      try {
         const result = await triggerTinMail({ biz_account_id: businessAccountId });
         if (result.ok) {
            setError('');
            toast.success(result?.message, { duration: 3000 });
         }
      } catch (err) {
         setError(err.message || err.custom_message);
         toast.error(err.message || err.custom_message, { duration: 3000 });
      }
   };

   const confirmTinMail = () => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isVerifying,
         onConfirm: processTIN,
      });
   };

   useEffect(() => {
      fetchBusinessDetails();
   }, []);

   return (
      <DocumentsWrapper>
         <AlertMessage message={error} variant="error" />
         {loading ? (
            <Spinner />
         ) : (
            <>
               {bizDetails?.business_type === 'registered' &&
                  businessAccountDocumentTypes(bizDetails).map((item) => (
                     <DocumentSection key={item.text}>
                        <DocumentHeading>
                           <p className="doc-label">{item.text}</p>
                           <BusinessAccountStatus status={item.key}>
                              {item.key}
                           </BusinessAccountStatus>
                        </DocumentHeading>
                     </DocumentSection>
                  ))}

               {bizDetails?.business_type === 'freelancer' &&
                  freelancerAccountDocumentTypes(bizDetails).map((item) => (
                     <DocumentSection key={item.text}>
                        <DocumentHeading>
                           <p className="doc-label">{item.text}</p>
                           <BusinessAccountStatus status={item.key}>
                              {item.key}
                           </BusinessAccountStatus>
                        </DocumentHeading>
                     </DocumentSection>
                  ))}

               {/* SELFIE */}
               <DocumentSection>
                  <DocumentHeading>
                     <p className="doc-label">Selfie</p>
                     <BusinessAccountStatus
                        status={getStatus(bizDetails?.owner_details?.selfie_status)}
                     >
                        {getStatus(bizDetails?.owner_details?.selfie_status)}
                     </BusinessAccountStatus>
                  </DocumentHeading>
                  <div className="mt-2">
                     {bizDetails?.owner_details?.selfie_image && (
                        <DocumentControls>
                           <DocumentPreviewer
                              url={bizDetails?.owner_details?.selfie_image}
                              biz_account_id={bizDetails.business_account}
                              title="Selfie"
                           >
                              <Button className="doc-btn me-3">Preview Document</Button>
                           </DocumentPreviewer>
                        </DocumentControls>
                     )}
                  </div>
               </DocumentSection>

               {/* PROOF OF ADDRESS */}
               <DocumentSection>
                  <DocumentHeading>
                     <p className="doc-label">Proof of Address</p>
                     <BusinessAccountStatus status={getStatus(bizDetails?.proof_of_address_status)}>
                        {getStatus(bizDetails?.proof_of_address_status)}
                     </BusinessAccountStatus>
                  </DocumentHeading>
                  <div className="mt-2">
                     {bizDetails?.proof_of_address_doc && (
                        <DocumentControls>
                           <DocumentPreviewer
                              url={bizDetails?.proof_of_address_doc}
                              biz_account_id={bizDetails.business_account}
                              title="Proof of Address"
                           >
                              <Button className="doc-btn me-3">Preview Document</Button>
                           </DocumentPreviewer>
                           {bizDetails?.proof_of_address_status !== 'confirmed' && (
                              <Button
                                 className="doc-btn bg-success me-3"
                                 onClick={() => handleVerify('proof_of_address_status', confirmed)}
                              >
                                 Approve
                              </Button>
                           )}
                           {bizDetails?.proof_of_address_status !== 'rejected' && (
                              <Button
                                 className="doc-btn bg-danger"
                                 onClick={() => handleVerify('proof_of_address_status', rejected)}
                              >
                                 Reject
                              </Button>
                           )}
                        </DocumentControls>
                     )}
                     <div className="input-group mt-5 doc-file-input-group">
                        <input
                           type="file"
                           className="form-control file-input"
                           aria-label="Upload"
                           onChange={(e) => setProofOfAddress(e.target.files[0])}
                        />
                        <Button
                           className="upload-btn"
                           disabled={!proofOfAddress || uploadingDoc === 'proof_of_address_doc'}
                           isLoading={uploadingDoc === 'proof_of_address_doc'}
                           onClick={() => uploadBizDoc('proof_of_address_doc')}
                        >
                           Upload Proof of Address
                        </Button>
                     </div>
                  </div>
               </DocumentSection>

               {/* BOARD RESOLUTION */}
               {bizDetails?.business_type === 'registered' && (
                  <DocumentSection>
                     <DocumentHeading>
                        <p className="doc-label">Board Resolution</p>
                        <BusinessAccountStatus
                           status={getStatus(bizDetails?.board_resolution_status)}
                        >
                           {getStatus(bizDetails?.board_resolution_status)}
                        </BusinessAccountStatus>
                     </DocumentHeading>
                     <div className="mt-2">
                        {bizDetails?.board_resolution_doc && (
                           <DocumentControls>
                              <DocumentPreviewer
                                 url={bizDetails?.board_resolution_doc}
                                 biz_account_id={bizDetails.business_account}
                                 title="Board Resolution"
                              >
                                 <Button className="doc-btn me-3">Preview Document</Button>
                              </DocumentPreviewer>
                              {bizDetails?.board_resolution_status !== 'confirmed' && (
                                 <Button
                                    className="doc-btn bg-success me-3"
                                    onClick={() =>
                                       handleVerify('board_resolution_status', confirmed)
                                    }
                                 >
                                    Approve
                                 </Button>
                              )}
                              {bizDetails?.board_resolution_status !== 'rejected' && (
                                 <Button
                                    className="doc-btn bg-danger"
                                    onClick={() =>
                                       handleVerify('board_resolution_status', rejected)
                                    }
                                 >
                                    Reject
                                 </Button>
                              )}
                           </DocumentControls>
                        )}
                        <div className="input-group mt-5 doc-file-input-group">
                           <input
                              type="file"
                              className="form-control file-input"
                              aria-label="Upload"
                              onChange={(e) => setBoardResolution(e.target.files[0])}
                           />
                           <Button
                              className="upload-btn"
                              disabled={!boardResolution || uploadingDoc === 'board_resolution_doc'}
                              isLoading={uploadingDoc === 'board_resolution_doc'}
                              onClick={() => uploadBizDoc('board_resolution_doc')}
                           >
                              Upload Board Resolution
                           </Button>
                        </div>
                     </div>
                  </DocumentSection>
               )}

               {/* STATUS REPORT */}
               {bizDetails?.business_type === 'registered' && (
                  <DocumentSection>
                     <DocumentHeading>
                        <p className="doc-label">Status Report</p>
                        <BusinessAccountStatus status={getStatus(bizDetails?.status_report_state)}>
                           {getStatus(bizDetails?.status_report_state)}
                        </BusinessAccountStatus>
                     </DocumentHeading>
                     <div className="mt-2">
                        {bizDetails?.status_report_doc && (
                           <DocumentControls>
                              <DocumentPreviewer
                                 url={bizDetails?.status_report_doc}
                                 biz_account_id={bizDetails.business_account}
                                 title="Status Report"
                              >
                                 <Button className="doc-btn me-3">Preview Document</Button>
                              </DocumentPreviewer>
                              {bizDetails?.status_report_state !== 'confirmed' && (
                                 <Button
                                    className="doc-btn bg-success me-3"
                                    onClick={() => handleVerify('status_report_state', confirmed)}
                                 >
                                    Approve
                                 </Button>
                              )}
                              {bizDetails?.status_report_state !== 'rejected' && (
                                 <Button
                                    className="doc-btn bg-danger"
                                    onClick={() => handleVerify('status_report_state', rejected)}
                                 >
                                    Reject
                                 </Button>
                              )}
                           </DocumentControls>
                        )}
                        {bizDetails?.status_report_state !== 'confirmed' && (
                           <div className="input-group mt-5 doc-file-input-group">
                              <input
                                 type="file"
                                 className="form-control file-input"
                                 aria-label="Upload"
                                 onChange={(e) => setStatusReport(e.target.files[0])}
                              />
                              <Button
                                 className="upload-btn"
                                 disabled={!statusReport || uploadingDoc === 'status_report_doc'}
                                 isLoading={uploadingDoc === 'status_report_doc'}
                                 onClick={() => uploadBizDoc('status_report_doc')}
                              >
                                 Upload Status Report
                              </Button>
                           </div>
                        )}
                     </div>
                  </DocumentSection>
               )}

               {/* TIN DOCUMENT  */}

               {bizDetails?.tin_status !== 'confirmed' &&
                  bizDetails?.business_type === 'registered' && (
                     <DocumentSection>
                        <DocumentHeading>
                           <p className="doc-label">Additional Documents for TIN 01</p>
                        </DocumentHeading>

                        <div className="mt-2">
                           {bizDetails?.tin_documents.length > 0 && (
                              <DocumentControls style={{ display: 'initial' }}>
                                 {bizDetails?.tin_documents.map((doc, i) => (
                                    <DocumentPreviewer
                                       key={doc?.id}
                                       url={doc.upload_file}
                                       biz_account_id={bizDetails.business_account}
                                       title="TIN Document 1"
                                    >
                                       <Button className="doc-btn me-3">
                                          Preview TIN Document {i + 1}
                                       </Button>
                                    </DocumentPreviewer>
                                 ))}
                                 <Button
                                    className="doc-btn bg-success me-3"
                                    onClick={confirmTinMail}
                                 >
                                    Send TIN Documents For Processing
                                 </Button>

                                 {bizDetails?.tin_status !== rejected && (
                                    <Button
                                       className="doc-btn bg-danger"
                                       onClick={() => {
                                          setTinDoc1(null);
                                          setTinDoc2(null);
                                          setTinDoc3(null);
                                          setTinDoc4(null);
                                       }}
                                    >
                                       Reject
                                    </Button>
                                 )}
                              </DocumentControls>
                           )}

                           <>
                              {!bizDetails?.tin_documents[0] && (
                                 <div className="input-group mt-5 doc-file-input-group">
                                    <input
                                       type="file"
                                       className="form-control file-input"
                                       aria-label="Upload"
                                       onChange={(e) => setTinDoc1(e.target.files[0])}
                                    />
                                    <Button
                                       className="upload-btn"
                                       disabled={!tinDoc1 || uploadingDoc === 'tin1'}
                                       isLoading={uploadingDoc === 'tin1'}
                                       onClick={() => uploadBizDoc('tin1')}
                                    >
                                       Upload TIN Document One
                                    </Button>
                                 </div>
                              )}
                              {!bizDetails?.tin_documents[1] && (
                                 <div className="input-group mt-5 doc-file-input-group">
                                    <input
                                       type="file"
                                       className="form-control file-input"
                                       aria-label="Upload"
                                       onChange={(e) => setTinDoc2(e.target.files[0])}
                                    />
                                    <Button
                                       className="upload-btn"
                                       disabled={!tinDoc2 || uploadingDoc === 'tin2'}
                                       isLoading={uploadingDoc === 'tin2'}
                                       onClick={() => uploadBizDoc('tin2')}
                                    >
                                       Upload TIN Document Two
                                    </Button>
                                 </div>
                              )}
                              {!bizDetails?.tin_documents[2] && (
                                 <div className="input-group mt-5 doc-file-input-group">
                                    <input
                                       type="file"
                                       className="form-control file-input"
                                       aria-label="Upload"
                                       onChange={(e) => setTinDoc3(e.target.files[0])}
                                    />
                                    <Button
                                       className="upload-btn"
                                       disabled={!tinDoc3 || uploadingDoc === 'tin3'}
                                       isLoading={uploadingDoc === 'tin3'}
                                       onClick={() => uploadBizDoc('tin3')}
                                    >
                                       Upload TIN Document Three
                                    </Button>
                                 </div>
                              )}
                              {!bizDetails?.tin_documents[3] && (
                                 <div className="input-group mt-5 doc-file-input-group">
                                    <input
                                       type="file"
                                       className="form-control file-input"
                                       aria-label="Upload"
                                       onChange={(e) => setTinDoc4(e.target.files[0])}
                                    />
                                    <Button
                                       className="upload-btn"
                                       disabled={!tinDoc4 || uploadingDoc === 'tin4'}
                                       isLoading={uploadingDoc === 'tin4'}
                                       onClick={() => uploadBizDoc('tin4')}
                                    >
                                       Upload TIN Document Four
                                    </Button>
                                 </div>
                              )}
                           </>
                        </div>
                     </DocumentSection>
                  )}
            </>
         )}
         <ReferencesContainer
            references={bizDetails?.references}
            fetchBusinessDetails={fetchBusinessDetails}
         />
      </DocumentsWrapper>
   );
};

NewDocuments.propTypes = {
   businessAccountId: number.isRequired,
   businessDetailId: number.isRequired,
};

export default NewDocuments;
